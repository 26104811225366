import { islandType } from '../types';

type videoIslandDictType = {
  [key in islandType]: string;
};
const introVideoIslandDict: videoIslandDictType = {
  start: 'https://www.youtube.com/embed/xTMKfFIH12M',
  asia: 'https://www.youtube.com/embed/alyJGvKg2qk',
  europe: 'https://www.youtube.com/embed/4LScE00xjWw',
  america: 'https://www.youtube.com/embed/IuAoHbe9z6U',
  oceania: 'https://www.youtube.com/embed/2FiTGlyem5c',
  africa: 'https://www.youtube.com/embed/kSiWQqNDuW4',
};

export default introVideoIslandDict;
