import { RunnerType } from 'adapters/repositories/typeDefinition';
import { convertRunnerGrade, is10GradesPackage } from 'components/constants';
import { nonFukushuGrades } from '../GoalsForEachGrade/targetsForEachGrade';
import { grades10, grades } from '../GradeGoalConst';

export const decideSampleVideoLink = (runner: RunnerType) => {
  if (
    !!runner.student_group &&
    ['TRAC', 'newtrac', 'trac', 'normal'].includes(runner.student_group)
  ) {
    const runnerGrade = convertRunnerGrade(runner);
    return sampleVideoDictForTRAC[runnerGrade];
  }
  if (is10GradesPackage(runner)) {
    const runnerGrade = convertRunnerGrade(runner);
    return sampleVideoDictFor10Grades[runnerGrade];
  }
  const runnerGrade = convertRunnerGrade(runner) as grades;
  if (!nonFukushuGrades.includes(runnerGrade)) {
    const weak_point = runner.weak_point || '20';
    return sampleVideoDictFor20Grades[weak_point];
  }
  return sampleVideoDictFor20Grades[runnerGrade];
};

type sampleVideoDict10Type = {
  [key in grades10]: string;
};

const sampleVideoDictFor10Grades: sampleVideoDict10Type = {
  10: 'https://www.youtube.com/embed/sZ76gFmG5kw',
  9: 'https://www.youtube.com/embed/RwOlXPLX0O8',
  8: 'https://www.youtube.com/embed/ctJ6sFe9rjg',
  7: 'https://www.youtube.com/embed/F5G0l_GqKkc',
  6: 'https://www.youtube.com/embed/2kWvq8aO5Co',
  5: 'https://www.youtube.com/embed/hZ3S_9bIKCg',
  4: 'https://www.youtube.com/embed/Cta-XP0f0xE',
  3: 'https://www.youtube.com/embed/1BqgOS35y-M',
  2: 'https://www.youtube.com/embed/1jV1BqnPCyk',
  1: 'https://www.youtube.com/embed/Vn70HQtQAmY',
  0: 'https://www.youtube.com/embed/Vn70HQtQAmY',
};

const sampleVideoDictForTRAC: sampleVideoDict10Type = {
  10: 'https://www.youtube.com/embed/E1Y-2q5_PjA',
  9: 'https://www.youtube.com/embed/Td5g7luiz2c',
  8: 'https://www.youtube.com/embed/jtGF0dSXeKI',
  7: 'https://www.youtube.com/embed/g5q8WHPklJg',
  6: 'https://www.youtube.com/embed/Y4AntK1ZEvw',
  5: 'https://www.youtube.com/embed/LGbiE4st5dA',
  4: 'https://www.youtube.com/embed/RXgtqW76k-g',
  3: 'https://www.youtube.com/embed/i4QkVCBpF3s',
  2: 'https://www.youtube.com/embed/XNE-dI1FW-U',
  1: 'https://www.youtube.com/embed/h65pRaOLPS8',
  0: 'https://www.youtube.com/embed/h65pRaOLPS8',
};

type sampleVideoDictType = {
  [key in grades]: string;
};

const sampleVideoDictFor20Grades: sampleVideoDictType = {
  20: 'https://www.youtube.com/embed/nl3qpYJ-wUs',
  19: 'https://www.youtube.com/embed/QZ2Q4eL5qVk',
  18: 'https://www.youtube.com/embed/ctJ6sFe9rjg',
  17: 'https://www.youtube.com/embed/sZ76gFmG5kw',
  16: 'https://www.youtube.com/embed/OjoXytKKCo8',
  15: 'https://www.youtube.com/embed/Cta-XP0f0xE',
  14: 'https://www.youtube.com/embed/sZ76gFmG5kw',
  13: 'https://www.youtube.com/embed/1BqgOS35y-M',
  12: 'https://www.youtube.com/embed/1jV1BqnPCyk',
  11: 'https://www.youtube.com/embed/1BqgOS35y-M',
  10: 'https://www.youtube.com/embed/sZ76gFmG5kw',
  9: 'https://www.youtube.com/embed/2kWvq8aO5Co',
  8: 'https://www.youtube.com/embed/hZ3S_9bIKCg',
  7: 'https://www.youtube.com/embed/o6eDB768UMg',
  6: 'https://www.youtube.com/embed/2kWvq8aO5Co',
  5: 'https://www.youtube.com/embed/t25uFoSDlfs',
  4: 'https://www.youtube.com/embed/4Yd3Sdpz3DQ',
  3: 'https://www.youtube.com/embed/Mf0DWNRPVUM',
  2: 'https://www.youtube.com/embed/1BqgOS35y-M',
  1: 'https://www.youtube.com/embed/Mf0DWNRPVUM', //ここまで変更DONE
  0: 'https://www.youtube.com/embed/Cta-XP0f0xE', //ここまで変更DONE
};
