import React, { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import './App.css';
import {
  Box,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Button,
  Divider,
} from '@material-ui/core';
import ContactMailIcon from '@mui/icons-material/ContactMail';
import VideoCameraBackIcon from '@mui/icons-material/VideoCameraBack';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import TravelExploreIcon from '@mui/icons-material/TravelExplore';
import { AccountType, RunnerType } from 'adapters/repositories/typeDefinition';

type Props = {
  account: AccountType;
  runner: RunnerType | undefined;
};
export const SwipableMenu: FC<Props> = ({ account, runner }) => {
  const [isDrawerOoen, setIsDrawerOpen] = React.useState(false);
  const navigate = useNavigate();

  const toggleDrawer = (open: boolean) => () => {
    setIsDrawerOpen(open);
  };

  const handleInquiryClick = () => {
    navigate(`/inquiry`);
  };

  const handleMyPageClick = () => {
    navigate(`/mypage`);
  };

  const list = () => (
    <Box role="presentation" onClick={toggleDrawer(false)}>
      <List>
        <ListItem>
          {' '}
          <span style={{ fontSize: '12pt', fontWeight: 'bold' }}>メニュー</span>
        </ListItem>
      </List>
      <Divider />
      <List>
        <ListItem button key={'mypage'}>
          <ListItemIcon>
            <ContactMailIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary={'マイページ'} onClick={handleMyPageClick} />
        </ListItem>

        <ListItem button key={'selectable'} disabled={!runner || runner.grade < 9}>
          <ListItemIcon>
            <TravelExploreIcon fontSize="small" />
          </ListItemIcon>

          <ListItemText
            primary={'上級編へ移動'}
            onClick={() => {
              localStorage.setItem('lastVisitedVersion', '/selectable/');
              navigate('/selectable/');
            }}
          />
        </ListItem>

        <ListItem button key={'basic'}>
          <ListItemIcon>🔰</ListItemIcon>
          <ListItemText
            primary={'初級編へ移動'}
            onClick={() => {
              localStorage.setItem('lastVisitedVersion', '/');
              navigate('/');
            }}
          />
        </ListItem>

        {account.email === 'f.akihiro.protein@gmail.com' && (
          <ListItem button key={'coach-video'}>
            <ListItemIcon>
              <VideoCameraBackIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText
              primary={'生徒動画送信'}
              onClick={() => {
                navigate('/coach-video');
              }}
            />
          </ListItem>
        )}
      </List>
      <Divider />
      <List>
        <ListItem button key={'inquiry'}>
          <ListItemText primary={'お問い合わせ'} onClick={handleInquiryClick} />
        </ListItem>
      </List>
    </Box>
  );

  return (
    <div>
      <Button size="small" onClick={toggleDrawer(true)} style={{ paddingTop: '5px' }}>
        <span style={{ fontSize: '9pt' }}>メニュー</span>
      </Button>

      <SwipeableDrawer
        open={isDrawerOoen}
        onClose={toggleDrawer(false)}
        onOpen={toggleDrawer(true)}
        // style={{ marginTop: '40px' }}
      >
        {list()}
      </SwipeableDrawer>
    </div>
  );
};
