import React from 'react';
import { calcFiftyTime } from 'pages/ResultPage/components/TimeViewer';

export const VideoLabel = ({ video }: any) => {
  if (!video.is_exam) {
    if (video.video_type === 'trial') {
      const fiftyTime = calcFiftyTime((!!video.score && video.score.real_speed) || 0);
      return (
        <>
          <div
            style={{
              fontWeight: 'bold',
              backgroundColor: '#00008b',
              color: 'white',
              fontSize: '8pt',
              borderRadius: '10px',
            }}
          >
            &nbsp;50m&nbsp;
          </div>
          <div>{fiftyTime.toFixed(2)}</div>
        </>
      );
    }

    if (!!video.no_account_info) {
      return (
        <>
          <div
            style={{
              fontWeight: 'bold',
              backgroundColor: '#00008b',
              color: 'white',
              fontSize: '8pt',
              borderRadius: '10px',
            }}
          >
            &nbsp;かけっこ診断&nbsp;
          </div>
        </>
      );
    }
    return (
      <div
        style={{
          fontWeight: 'bold',
          backgroundColor: '#87cefa',
          color: 'white',
          fontSize: '8pt',
          borderRadius: '10px',
        }}
      >
        &nbsp;練習用&nbsp;
      </div>
    );
  }

  if (!video.score) {
    return (
      <div
        style={{
          fontWeight: 'bold',
          backgroundColor: '#ffa500',
          color: 'white',
          fontSize: '8pt',
          borderRadius: '10px',
        }}
      >
        &nbsp;採点未入力&nbsp;
      </div>
    );
  }

  if (!!video.score && !video.checked_by_boss) {
    return (
      <div
        style={{
          fontWeight: 'bold',
          backgroundColor: '#ff69b4',
          color: 'white',
          fontSize: '8pt',
          borderRadius: '10px',
        }}
      >
        &nbsp;承認待ち&nbsp;
      </div>
    );
  }

  return (
    <div
      style={{
        fontWeight: 'bold',
        backgroundColor: '#3cb371',
        color: 'white',
        fontSize: '8pt',
        borderRadius: '10px',
      }}
    >
      &nbsp;承認済み&nbsp;
    </div>
  );
};
