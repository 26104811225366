import canada_icon from 'features/selectable_training/static/canada_icon.png';
import america_icon from 'features/selectable_training/static/america_icon.png';
import jamaica_icon from 'features/selectable_training/static/jamaica_icon.png';
import china_icon from 'features/selectable_training/static/china_icon.png';
import india_icon from 'features/selectable_training/static/india_icon.png';
import qatar_icon from 'features/selectable_training/static/qatar_icon.png';
import papua_icon from 'features/selectable_training/static/papua_icon.png';
import australia_icon from 'features/selectable_training/static/australia_icon.png';
import nzl_icon from 'features/selectable_training/static/nzl_icon.png';
import germany_icon from 'features/selectable_training/static/germany_icon.png';
import britain_icon from 'features/selectable_training/static/britain_icon.png';
import norway_icon from 'features/selectable_training/static/norway_icon.png';
import south_africa_icon from 'features/selectable_training/static/south_africa_icon.png';
import morocco_icon from 'features/selectable_training/static/morocco_icon.png';
import kenya_icon from 'features/selectable_training/static/kenya_icon.png';
import tokyo_icon from 'features/selectable_training/static/tokyo_icon.png';
import kyoto_icon from 'features/selectable_training/static/kyoto_icon.png';
import sapporo_icon from 'features/selectable_training/static/sapporo_icon.png';
import { nationType } from 'constants/nations';

type nationImgType = {
  [key in nationType]: string;
};

export const nationImgDict: nationImgType = {
  インド: india_icon,
  中国: china_icon,
  カタール: qatar_icon,
  カナダ: canada_icon,
  アメリカ: america_icon,
  ジャマイカ: jamaica_icon,
  パプアニューギニア: papua_icon,
  ニュージーランド: nzl_icon,
  オーストラリア: australia_icon,
  モロッコ: morocco_icon,
  ケニア: kenya_icon,
  ドイツ: germany_icon,
  ノルウェー: norway_icon,
  イギリス: britain_icon,
  南アフリカ: south_africa_icon,
  東京: tokyo_icon,
  京都: kyoto_icon,
  札幌: sapporo_icon,
};
