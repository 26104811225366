import React, { FC, useContext } from 'react';
import HomeIcon from '@mui/icons-material/Home';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import DateRangeIcon from '@mui/icons-material/DateRange';

import QuestionAnswerOutlinedIcon from '@mui/icons-material/QuestionAnswerOutlined';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import { useLocation, useNavigate } from 'react-router-dom';
import classes from './footer.module.css';
import { Badge } from '@mui/material';
// import { RunnerType } from 'adapters/repositories/typeDefinition';
import ImportContactsIcon from '@mui/icons-material/ImportContacts';
import { AccountContext } from 'contexts/AccountContext';
import gorin_blue from '../../static/gorin_blue_2.png';
import gorin_gray from '../../static/gorin_gray_2.png';
import { UploadFAB } from './UploadFAB';

const WorldFooter: FC = () => {
  const { currentRunner } = useContext(AccountContext);
  const runner = currentRunner;
  const navigate = useNavigate();
  const location = useLocation();
  React.useEffect(() => {
    setCurrentPath(location.pathname);
  }, [location]);

  const [currentPath, setCurrentPath] = React.useState('/');

  const iconSize = 24;

  const handleVideoList = () => {
    navigate(`/selectable/ask`);
  };

  return (
    <div className={classes.footer}>
      {currentPath.indexOf('/result') === -1 &&
      currentPath.indexOf('/boss-check') === -1 &&
      currentPath.indexOf('/scoring') === -1 ? (
        <>
          <UploadFAB />
        </>
      ) : (
        <></>
      )}

      <div style={{ display: 'flex', width: '100%' }}>
        <div
          style={{ textAlign: 'center', width: '20%' }}
          onClick={() => navigate('/selectable/')}
        >
          {currentPath === '/selectable/' ||
          currentPath.indexOf('/selectable/training') !== -1 ? (
            <>
              <HomeIcon
                className={classes.selectedIcon}
                sx={{ fontSize: iconSize }}
              />
              <div className={classes.selectedCharacter}>ホーム</div>
            </>
          ) : (
            <>
              <HomeOutlinedIcon
                sx={{ fontSize: iconSize }}
                className={classes.unselectedIcon}
              />
              <div className={classes.unselectedCharacter}>ホーム</div>
            </>
          )}
        </div>

        <div
          style={{ textAlign: 'center', width: '20%' }}
          onClick={() => navigate('/selectable/achievements/')}
        >
          {(currentPath === '/selectable/achievements/' && (
            <>
              <div>
                <img src={gorin_blue} alt="gorin" width="24pt" />
              </div>
              <div className={classes.selectedCharacter}>せいせき</div>
            </>
          )) || (
            <>
              <div>
                <img src={gorin_gray} alt="gorin" width="24pt" />
              </div>

              <div className={classes.unselectedCharacter}>せいせき</div>
            </>
          )}
        </div>

        <div
          style={{ textAlign: 'center', width: '20%' }}
          onClick={() => navigate('/selectable/videolist/')}
        >
          {(currentPath === '/selectable/videolist/' && (
            <>
              <DateRangeIcon
                className={classes.selectedIcon}
                sx={{ fontSize: iconSize }}
              />
              <div className={classes.selectedCharacter}>練習のきろく</div>
            </>
          )) || (
            <>
              <DateRangeIcon
                className={classes.unselectedIcon}
                sx={{ fontSize: iconSize }}
              />
              <div className={classes.unselectedCharacter}>練習のきろく</div>
            </>
          )}
        </div>

        <div
          style={{ textAlign: 'center', width: '20%' }}
          onClick={() => navigate('/selectable/usage/')}
        >
          {currentPath === '/selectable/usage/' ? (
            <>
              <ImportContactsIcon
                className={classes.selectedIcon}
                sx={{ fontSize: iconSize }}
              />
              <div className={classes.selectedCharacter}>使い方</div>
            </>
          ) : (
            <>
              <>
                <ImportContactsIcon
                  className={classes.unselectedIcon}
                  sx={{ fontSize: iconSize }}
                />
                <div className={classes.unselectedCharacter}>使い方</div>
              </>
            </>
          )}
        </div>

        <div
          style={{ textAlign: 'center', width: '20%' }}
          onClick={handleVideoList}
        >
          {currentPath === '/ask' || currentPath.indexOf('/ask') !== -1 ? (
            <>
              <QuestionAnswerIcon
                className={classes.selectedIcon}
                sx={{ fontSize: iconSize }}
              />
              <div className={classes.selectedCharacter}>個別に相談</div>
            </>
          ) : (
            <>
              <QuestionAnswerOutlinedIcon
                sx={{ fontSize: iconSize }}
                className={classes.unselectedIcon}
              />
              <div className={classes.unselectedCharacter}>個別に相談</div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default WorldFooter;
