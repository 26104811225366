import { RunnerType } from 'adapters/repositories/typeDefinition';
import React, { FC, memo } from 'react';
import joy_boy from 'static/materials/joy_boy.png';
import classes from '../onboardingCarousel.module.css';
import iiduka from 'features/selectable_course/static/onishi.png';

import { crtYobina } from 'utils/funcs/crtYobina';

type Props = {
  runner: RunnerType;
};

const FirstCut: FC<Props> = memo(({ runner }) => {
  return (
    <>
      <div className={classes.blackBorder} style={{ fontSize: '0.96rem' }}>
        {crtYobina(runner)} こんにちは！
        <br />
        IDATEN Worldへようこそ！
        <br />
        ここでは世界の国々をまわりながら <br />
        速く走るための練習をしていくよ！
      </div>
      <div className={classes.iidukaFirstWrapper}>
        <img src={iiduka} alt="" width="100%"></img>
      </div>
    </>
  );
});

export default FirstCut;
