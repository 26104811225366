import { islandType } from 'features/selectable_course/types';
import africa_exam from '../static/africa_exam.png';
import asia_exam from '../static/asia_exam.png';
import europe_exam from '../static/europe_exam.png';
import america_exam from '../static/america_exam.png';
import japan_exam from '../static/japan_exam.png';
import oceania_exam from '../static/oceania_exam.png';

export const decideImgForExamScreen = (island: islandType) => {
  switch (island) {
    case 'start':
      return japan_exam;
    case 'asia':
      return asia_exam;
    case 'america':
      return america_exam;
    case 'europe':
      return europe_exam;
    case 'oceania':
      return oceania_exam;
    case 'africa':
      return africa_exam;
    default:
      throw new Error('Invalid input');
  }
};
