import { RunnerType } from 'adapters/repositories/typeDefinition';
import React, { FC, memo } from 'react';
import world_map_img from 'features/selectable_course/static/world_map.png';
import classes from '../onboardingCarousel.module.css';

type Props = {
  runner: RunnerType;
};

const SecondCut: FC<Props> = memo(({ runner }) => {
  return (
    <>
      <div className={classes.blackBorder} style={{ fontSize: '0.96rem', marginBottom: '24px' }}>
        地域は全部で6つ！ <br />
        どの地域から始めてもOK！
        <br />
        全ての練習をクリアして
        <br />
        速く走れるようになろう！
      </div>
      <div className={classes.mapSecondWrapper}>
        <img src={world_map_img} alt="" width="100%"></img>
      </div>
    </>
  );
});

export default SecondCut;
