import React, { FC } from 'react';

type Props = {
  title: string;
};
export const ToDos: FC<Props> = ({ title }) => {
  return (
    <div style={{ display: 'flex' }}>
      <div
        style={{
          marginRight: '10px',
          color: '#005500',
        }}
      >
        ✅
      </div>
      <div style={{ fontWeight: 'bold' }}>{title}</div>
    </div>
  );
};
