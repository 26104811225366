import React, { FC } from 'react';
import { PageTitle } from 'components/common/PageTitle';
import { useFirstOnboardingModal } from 'hooks/FirstOnboarding/useFirstOnboardingModal';
import { AccountContext } from 'contexts/AccountContext';
import { Accordion, AccordionDetails, AccordionSummary, Button } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import finger from 'features/selectable_course/static/world_map_finger.png';
import topView from 'features/selectable_course/static/topViewExplanation.png';
import practiceView from 'features/selectable_course/static/practicePage.png';

const UsagePage: FC = () => {
  const { currentRunner } = React.useContext(AccountContext);
  const { forceModalOpen, OnboardModal } = useFirstOnboardingModal(currentRunner, true);

  const handleClick = () => {
    forceModalOpen(true);
  };
  return (
    <>
      <PageTitle title={'このアプリの使い方'} />
      <div style={{ margin: '16px' }}>
        IDATEN中級編では、世界の各地域を巡りながら足が速くなるための基礎を一つずつ身に付けていきます！
        <div style={{ textAlign: 'center', width: '99%' }}>
          <Button variant="outlined" onClick={handleClick}>
            使い方の流れを見る
          </Button>
        </div>
        {OnboardModal}
        <div style={{ marginTop: '24px' }}>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              1. まずは好きな地域を選択！
            </AccordionSummary>
            <AccordionDetails>
              <>
                左下の「ホーム」を押すと大きな地図が出てきます。興味のある地域をタップしましょう！
                <div style={{ width: '80vw' }}>
                  <img src={finger} alt="" width="100%"></img>
                </div>
              </>
            </AccordionDetails>
          </Accordion>

          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              2. 各地域の練習と解説動画を見る！
            </AccordionSummary>
            <AccordionDetails>
              <>
                興味のある地域を選んだらまずは宝箱を押してみよう。その地域の練習について解説動画を見られるよ！
                <div style={{ width: '80vw' }}>
                  <img src={topView} alt="" width="100%"></img>
                </div>
              </>
            </AccordionDetails>
          </Accordion>

          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              3. テストに挑戦する！
            </AccordionSummary>
            <AccordionDetails>
              <>
                ダンベルを押すと練習ページに移動します。やりたい練習の画像をタップして、説明動画を見てみましょう！動画を見たら練習に取り組んでいる動画を指示通りに撮影しましょう。
                <div style={{ width: '80vw' }}>
                  <img src={practiceView} alt="" width="100%"></img>
                </div>
              </>
            </AccordionDetails>
          </Accordion>

          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              4. 動画のアップロード！
            </AccordionSummary>
            <AccordionDetails>
              <>
                右下にある水色のボタンから動画のアップロードを行うことができます。自分がやったメニューを選んで、先生へのコメントや質問と一緒にアップロードしましょう！
              </>
            </AccordionDetails>
          </Accordion>
        </div>
        <div style={{ marginTop: '100px' }}>
          <br />
        </div>
      </div>
    </>
  );
};

export default UsagePage;
