import React from 'react';
import classes from 'components/MenuConst/menutextDecoration.module.css';

export const howToStartVideos = [
  {
    link: 'https://www.youtube.com/embed/sLO-uws-XLs',
    title: 'スタート姿勢の作り方',
    message: (
      <div>
        <div className={classes.subtitle}>
          （動画1）「正しいスタート姿勢」をマスターしよう！！（動画のお手本の形をマネしてみよう！）
        </div>
        ☆保護者さんなどにうまくできているかチェックしてもらおう！
        <div style={{ textAlign: 'center', fontWeight: 'bold', padding: '6px' }}>
          チェックポイント
        </div>
        1. 前あしに体重をのせる
        ☆後ろ足のかかとはういているかな？前あしの膝が足首のだいたい真上にあるかな？
        <br />
        2. いい姿勢で体をたおす ☆横から見て上体と後ろ足がまっすぐかな？目線は3m先を見れているかな？
        <br />
        <br />
        ☆腕は前後にひらくんでも下にたらすんでも、やりやすい形でOK！
      </div>
    ),
  },
  {
    link: 'https://www.youtube.com/embed/SbSr3_5aXTI',
    title: '1次加速の練習',
    message: (
      <div>
        <div className={classes.subtitle}>（動画2）「スタートから10mまでの走り方」を学ぶ！！</div>
        ⇒倒れこみダッシュと壁押しを練習！
        <br />
        ⇒「10mダッシュ」にたくさんチャレンジ！
        <div style={{ color: 'red', fontWeight: 'bold', padding: '6px' }}>
          ☆昇級テストはこちら！「右下+ボタン⇒昇級に挑戦」から自信のある1本を送信！
        </div>
        <div style={{ textAlign: 'center', fontWeight: 'bold', padding: '6px' }}>
          昇級のポイント
        </div>
        1. （スタートの構え）前あしに体重をのせる
        ☆後ろ足のかかとはういているかな？前あしの膝が足首のだいたい真上にあるかな？
        <br />
        2. （スタートの構え）いい姿勢で体をたおす
        ☆横から見て上体と後ろ足がまっすぐかな？目線は3m先を見れているかな？
        <br />
        3. しっかり体を倒す ☆6歩以上体をたおして走ろう！あごをひいて、目線は5m先くらい！
        <br />
        ※のぼり坂で10mダッシュの練習をすると意識しやすいかも！近場にのぼり坂のある子は試してみてね！
        <div style={{ textAlign: 'center', fontWeight: 'bold', padding: '6px' }}>
          ～うえ3つができた子はチャレンジ！～
        </div>
        4. 足を前に前に ☆かべおしのときと同じ！
        <br />
        5. 腕ふり ☆前に前にふろう！
      </div>
    ),
  },
];

////////////////////////////////////////////////////
// TRAC
////////////////////////////////////////////////////

export const one_leg_jump = [
  {
    link: 'https://www.youtube.com/embed/cFXPn4ou5LQ',
    title: 'その場片足ジャンプ',
    message: (
      <div>
        <div className={classes.subtitle}>その場片足ジャンプ</div>
        <div className={classes.content}>☆ぜひ動画のまねをしてやってみてね！</div>
      </div>
    ),
  },
];

export const jigzag_one_leg_jump = [
  {
    link: 'https://www.youtube.com/embed/RrLgh44qzYI',
    title: '片足ジグザグジャンプ',
    message: (
      <div>
        <div className={classes.subtitle}>片足ジグザグジャンプ</div>
        <div className={classes.content}>☆ぜひ動画のまねをしてやってみてね！</div>
      </div>
    ),
  },
];

export const side_hop = [
  {
    link: 'https://www.youtube.com/embed/e7miySfsAE4',
    title: 'サイドケンケン',
    message: (
      <div>
        <div className={classes.subtitle}>サイドケンケン</div>
        <div className={classes.content}>☆ぜひ動画のまねをしてやってみてね！</div>
      </div>
    ),
  },
];

export const side_hop_arm_raise = [
  {
    link: 'https://www.youtube.com/embed/i4QkVCBpF3s',
    title: '片足腕上げジャンプ',
    message: (
      <div>
        <div>
          <div className={classes.subtitle}>
            片足腕上げジャンプ！
            <br />
            ☆昇級テストの挑戦は右下の ➕ボタン⇒昇級に挑戦」から行えます！
          </div>
          <div className={classes.title_important}>昇級のポイント</div>
          <div className={classes.content}>
            1. 良いしせいを作る！
            <br />
            2. ひざが曲がらないように！
            <br />
            3. 腕をまっすぐ上げる！
          </div>
        </div>
      </div>
    ),
  },
];
