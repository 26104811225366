import React, { FC, useCallback } from 'react';
import { CustomizedModalWrapper } from 'components/modals/ModalWrapper';
import { RunnerType } from 'adapters/repositories/typeDefinition';
import basePaymentImg from 'static/materials/trac_payment_modal.png';
import joy_girl from 'static/materials/joy_girl.png';
import { Button } from '@material-ui/core';

export const usePaymentModal = (runner: RunnerType | undefined) => {
  const [open, setOpen] = React.useState(false);

  const toggleModalOpen = () => {
    setOpen(!open);
  };

  const forcePaymentModalOpen = useCallback((openOrClose: boolean) => {
    setOpen(openOrClose);
  }, []);

  return {
    forcePaymentModalOpen,
    toggleModalOpen: toggleModalOpen,
    PaymentModal: (runner && (
      <PaymentModal isModalOpen={open} setIsModalOpen={setOpen} grade={runner.grade} />
    )) || <></>,
  };
};

type Props = {
  isModalOpen: boolean;
  grade: number;
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

const PaymentModal: FC<Props> = ({ isModalOpen, setIsModalOpen, grade }) => {
  console.log(grade);

  return (
    <CustomizedModalWrapper isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <div style={{ width: '20%' }}>
          <img src={joy_girl} alt="" width="60%"></img>
        </div>
        <div style={{ width: '80%' }}>
          <div
            style={{
              padding: '6px',
              fontSize: '12pt',
              fontWeight: 'bold',
              borderRadius: '20px',
              border: '3px #ffd700 solid',
            }}
          >
            期間限定の
            <br />
            無料体験はここまで！
          </div>
        </div>
      </div>
      <div style={{ marginTop: '8px' }}>
        <img src={basePaymentImg} alt="" width="90%"></img>
      </div>
      <div style={{ marginTop: '8px' }}>
        <Button
          variant="contained"
          style={{
            backgroundColor: 'green',
            color: 'white',
            fontWeight: 'bold',
            fontSize: '12pt',
            padding: '8px 30px 8px 30px',
          }}
          onClick={() => {
            window.open('https://tractokyo.base.shop/items/75422458', '_blank');
          }}
        >
          申し込む！
        </Button>
      </div>
    </CustomizedModalWrapper>
  );
};
