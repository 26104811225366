import React, { FC } from 'react';
import axios from 'axios';
import { Button, CircularProgress, Box, LinearProgress, Typography } from '@material-ui/core';
import { HOST } from 'v1apiRequest';
import loadingGif from 'static/running-101-gif.gif';
import { random_16_char } from 'utils/funcs/random_char_generator';

export type UploadButtonProps = {
  activeStep: number;
  params: FormData;
  completeFunc: () => void;
  failFunc: () => void;
  formContent: string;
  title?: string;
  color?: 'primary' | 'secondary';
};

const VideoUploadButtonByS3: FC<UploadButtonProps> = ({
  activeStep,
  params,
  completeFunc,
  formContent,
  failFunc,
  color = 'primary',
  title = '動画を送信',
}) => {
  const [progress, setProgress] = React.useState(0.0);
  const [isUploading, setIsUploading] = React.useState<boolean>(false);

  const sendVideoToS3 = async () => {
    const content = params.get('content') as File;

    // 練習動画なしでアップロードする場合
    if (!content) {
      console.log(params);
      const resFromAPI = await axios
        .post(`${HOST}/post-videos-with-s3/`, params)
        .then((res) => {
          completeFunc();
          setIsUploading(false);
        })
        .catch((res) => {
          failFunc();
          setIsUploading(false);
        });
      return;
    }
    const config = {
      onUploadProgress: function (progressEvent: ProgressEvent) {
        const percentCompleted = Math.round((progressEvent.loaded * 99) / progressEvent.total);
        console.log(percentCompleted);
        setProgress(percentCompleted);
      },
      headers: {
        'Content-Type': content.type,
      },
    };

    setIsUploading(true);
    const fileName = params.get('fileName') as string;

    const fileParts = fileName.split('.');
    const nameWithoutExtension = fileParts.slice(0, -1).join('.');
    const extension = fileParts.slice(-1)[0];

    // ランダムな文字列をファイル名の終わりに追加し、拡張子を再追加
    const fileKey = `${nameWithoutExtension}_${random_16_char()}.${extension}`;
    const res_url = await axios.post(`${HOST}/get-signed-url`, {
      file_name: fileKey,
    });
    const signedURL = res_url.data as string;
    // eslint-disable-next-line
    let is_s3_put_success = false;
    const response_s3 = await axios
      .put(signedURL, params.get('content'), config)
      .then((res) => {
        console.log('Upload successful', res);
        is_s3_put_success = true;
        params.delete('content');
        return res;
      })
      .catch((e) => {
        console.log(e);
        setIsUploading(false);
        alert('失敗');
        return e;
      });
    console.log(response_s3);

    if (!is_s3_put_success) {
      return;
    }

    // s3のアップロードが完了したらBEに保存
    params.append('file_path', fileKey);
    const resFromAPI = await axios
      .post(`${HOST}/post-videos-with-s3/`, params)
      .then((res) => {
        console.log(res);
        completeFunc();
        setIsUploading(false);
      })
      .catch((res) => {
        failFunc();
        setIsUploading(false);
      });
  };

  //console.log(params, activeStep);

  if (activeStep >= 1 && !!formContent && formContent.length > 0) {
    return (
      <div style={{ marginTop: '20px' }}>
        {isUploading ? (
          <>
            送信中 <CircularProgress size={20} />
            <div>
              <LinearProgressWithLabel progress={progress} />
            </div>
            <div>
              <img alt="ロード中" src={loadingGif} height="150" width="auto" />
            </div>
          </>
        ) : (
          <>
            <Button
              variant="contained"
              color={color || 'primary'}
              onClick={() => {
                sendVideoToS3();
              }}
            >
              {title}
            </Button>
          </>
        )}
      </div>
    );
  }
  return (
    <>
      <Button variant="contained" disabled>
        {title}
      </Button>
    </>
  );
};

const LinearProgressWithLabel = ({ progress }: { progress: number }) => {
  return (
    <Box display="flex" alignItems="center">
      <Box width="100%" mr={1}>
        <LinearProgress variant="determinate" value={progress} />
      </Box>
      <Box minWidth={35}>
        <Typography variant="body2" color="textSecondary">{`${progress}%`}</Typography>
      </Box>
    </Box>
  );
};

VideoUploadButtonByS3.defaultProps = {
  title: '動画を送信', // ここにtitleのデフォルト値を設定
  color: 'primary', // ここにcolorのデフォルト値を設定
};

export default VideoUploadButtonByS3;
