import { CardForFirstLogin } from 'features/videoLogs/components/VideoList/CardForFirstLogin';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { VideoCard } from './components/VideoCard';
import { useGetVideoByRunner } from 'hooks/useGetVideoByRunner';
import { AccountContext } from 'contexts/AccountContext';

const SelectableLogPage = () => {
  const { currentRunner } = React.useContext(AccountContext);
  const { videoList, isLoading } = useGetVideoByRunner(currentRunner);

  const videoListJokyu = videoList?.filter((v) => !!v.menu);
  const navigate = useNavigate();
  const changeVideoId = (vId: string) => () => {
    navigate(`/selectable/result/${vId}`);
    //どうやらスラッシュ入れるのがabsolutepathらしい
  };
  console.log(videoList);

  return (
    <div style={{ textAlign: 'center' }}>
      <div
        style={{
          margin: '3px',
          overflowY: 'scroll',
          height: `${window.parent.screen.height * 0.8}px`,
          border: '3px solid #EEEEEE',
        }}
      >
        {!!videoListJokyu && videoListJokyu.length > 0 ? (
          <>
            {videoListJokyu.map((vObj) => {
              return <VideoCard key={vObj.id} video={vObj} handleClick={changeVideoId} />;
            })}
          </>
        ) : (
          <div>
            <p>動画がまだ投稿されていません</p>
            <CardForFirstLogin />
          </div>
        )}
      </div>
    </div>
  );
};

export default SelectableLogPage;
