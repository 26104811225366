import React, { memo, useEffect, FC } from 'react';
import classes from './progressbar.module.css';
import { decideContinent } from '../funcs/decideContinent';
import { completedContriesType } from 'adapters/repositories/typeDefinition';
import { nationImgDict } from 'features/selectable_course/constants/continentCountries';
import { continentColorDict, continentNationDict, continentWithExam } from 'constants/nations';

type CollectionProps = {
  countriesDict: completedContriesType;
};

export const BarGraphCollection: FC<CollectionProps> = memo(({ countriesDict }) => {
  console.log(countriesDict);
  const completedNations = extractCompletedNations(countriesDict);
  console.log(completedNations);
  return (
    <>
      {continentWithExam.map((c) => {
        const imgPath = decideContinent(c);
        const nationsOfTheContinent = continentNationDict[c];
        const perentage =
          (nationsOfTheContinent.filter((nation) => completedNations.includes(nation)).length /
            nationsOfTheContinent.length) *
          100;
        const strokeColor = continentColorDict[c];
        return (
          <div style={{ display: 'flex', marginTop: '10px' }} key={c}>
            <div style={{ width: '15vw' }}>
              <img src={imgPath} alt="大陸" width="100%" />
            </div>
            <div style={{ width: '60vw', paddingTop: '2vw', marginLeft: '5vw' }}>
              <BarGraph percent={Math.floor(perentage)} strokeColor={strokeColor} />
              <div style={{ display: 'flex', marginTop: '8px' }}>
                {nationsOfTheContinent.map((n) => {
                  const opacity = (completedNations.includes(n) && 1) || 0.3;
                  const nationImgPath = nationImgDict[n] || imgPath;
                  return (
                    <div
                      style={{
                        width: '5vw',
                        marginRight: '2vw',
                        opacity: opacity,
                      }}
                      key={n}
                    >
                      <img
                        src={nationImgPath}
                        alt="大陸"
                        width="100%"
                        style={{
                          outline: '2px solid white',
                          borderRadius: '50%',
                        }}
                      />
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        );
      })}
    </>
  );
});

type Props = {
  percent: number;
  strokeColor: string;
};

const BarGraph: FC<Props> = ({ percent, strokeColor }) => {
  const [progress, setProgress] = React.useState(0);
  useEffect(() => {
    const interval = setInterval(() => {
      setProgress((prevProgress) => {
        const nextProgress = prevProgress + percent / 100;
        return nextProgress >= percent ? percent : nextProgress;
      });
    }, 5);

    return () => {
      clearInterval(interval);
    };
  }, [percent]);

  return (
    <div>
      <div className={classes.progressBarContainer}>
        <div
          className={classes.linearProgressBar}
          style={{ width: `${progress}%`, backgroundColor: strokeColor }}
        ></div>
      </div>
    </div>
  );
};

export function extractCompletedNations(countries: completedContriesType) {
  let retList = [];
  for (const [key, value] of Object.entries(countries)) {
    if (value % 1 > 0.1 && value % 1 < 0.9) {
      retList.push(key);
    }
  }
  return retList;
}
