import React, { FC, memo } from 'react';
import classes from './onboardingCarousel.module.css';
import joy_boy from 'static/materials/joy_boy.png';
import lineIcon from 'static/materials/line.png';
import { Button } from '@material-ui/core';
import { RunnerType } from 'adapters/repositories/typeDefinition';

type Props = {
  runner: RunnerType;
};
export const EighthView: FC<Props> = memo(({ runner }) => {
  const lineLink = decideLineLink(runner);
  return (
    <>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <div style={{ width: '20%' }}>
          <img src={joy_boy} alt="" width="60%"></img>
        </div>
        <div style={{ width: '80%' }}>
          <div className={classes.blackBorderSmall}>LINE友達追加のお願い</div>
        </div>
      </div>
      <div
        style={{
          marginTop: '32px',
          fontSize: '12pt',
          color: '#424242',
          fontWeight: 'bold',
        }}
      >
        <div>
          お客様には
          <br />「<span style={{ color: 'red' }}>公式LINEのご登録</span>
          」をお願いしています。
        </div>
        <LINEButton link={lineLink} />

        <div style={{ marginTop: '12px' }}>
          次は何をしたら良いの？
          <br />
          アプリが不具合を起こした、
          <br />
          そのようなお困りごとは、ご遠慮なく
          <br />
          運営までご相談ください！
        </div>
      </div>
    </>
  );
});

type LINEButtonProps = {
  link: string;
};

const LINEButton: FC<LINEButtonProps> = memo(({ link }) => {
  return (
    <a href={link} target="_blank" rel="noreferrer" style={{ textDecoration: 'none' }}>
      <Button
        style={{
          width: '80vw',
          backgroundColor: '#3cb371',
          color: '#FFF',
          fontWeight: 'bold',
          fontSize: '12pt',
          borderRadius: '40px',
          margin: '10vw 0 6vw 0',
        }}
        variant="contained"
      >
        <img src={lineIcon} alt="" width="40px"></img>
        <span> &nbsp;&nbsp;&nbsp;友達追加はこちら</span>
      </Button>
    </a>
  );
});

export const decideLineLink = (runner: RunnerType | undefined) => {
  const student_group = runner?.student_group;
  return 'https://lin.ee/GYFMyey';
};
