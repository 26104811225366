import React, { ReactNode } from 'react';
import { FooterComponent } from './PageTemplate';
import { RunnerType } from 'adapters/repositories/typeDefinition';
import { MemberHeader } from 'components/header/MemberHeader';

type Props = {
  children: ReactNode;
  currentRunner: RunnerType | undefined;
};

export const withFooter = ({ children, currentRunner }: Props) => {
  return (
    <>
      <MemberHeader />
      <main>
        {children}
        <footer>
          <FooterComponent runner={currentRunner} />
        </footer>
      </main>
    </>
  );
};
