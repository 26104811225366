import React, { FC } from 'react';
import HomeIcon from '@mui/icons-material/Home';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import DateRangeIcon from '@mui/icons-material/DateRange';

import ContactSupportIcon from '@mui/icons-material/ContactSupport';
import ContactSupportOutlinedIcon from '@mui/icons-material/ContactSupportOutlined';
import FitnessCenterIcon from '@mui/icons-material/FitnessCenter';
import FitnessCenterOutlinedIcon from '@mui/icons-material/FitnessCenterOutlined';
import { useLocation, useNavigate } from 'react-router-dom';
import { UploadFAB } from './FAB/FloatingActionButton';
import { UploadModal } from './FAB/UploadModal';
import classes from './footer.module.css';
import { Badge } from '@mui/material';
import { RunnerType } from 'adapters/repositories/typeDefinition';
import { decideOnboardState } from 'pages/MemberHome/func/decideOnboardState';

type FooterProps = {
  runner: RunnerType | undefined;
};

export const FooterComponent: FC<FooterProps> = ({ runner }) => {
  const navigate = useNavigate();
  const location = useLocation();
  React.useEffect(() => {
    setCurrentPath(location.pathname);
  }, [location]);
  const onboardState = !!runner && decideOnboardState(runner);

  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [currentPath, setCurrentPath] = React.useState('/');

  const iconSize = 24;

  const handleTrialClick = () => {
    navigate('/practice');
  };

  const handleVideoList = () => {
    navigate(`/announce`);
  };

  const onVideoClick = () => {
    navigate(`/upload`);
    setIsModalOpen(false);
  };

  const onManualClick = () => {
    navigate(`/manual-upload`);
    setIsModalOpen(false);
  };

  return (
    <div className={classes.footer}>
      {currentPath.indexOf('/result') === -1 &&
      currentPath.indexOf('/boss-check') === -1 &&
      currentPath.indexOf('/scoring') === -1 ? (
        <>
          <UploadModal
            isModalOpen={isModalOpen}
            setIsModalOpen={setIsModalOpen}
            onVideoClick={onVideoClick}
            onManualClick={onManualClick}
          />
          <UploadFAB
            goToUploadPage={() => {
              setIsModalOpen(true);
            }}
          />
        </>
      ) : (
        <></>
      )}

      <div style={{ display: 'flex', width: '100%' }}>
        <div
          style={{ textAlign: 'center', width: '25%' }}
          onClick={() => navigate('/')}
        >
          {currentPath === '/' ||
          currentPath.indexOf('/login') !== -1 ||
          currentPath.indexOf('/relogin') !== -1 ||
          currentPath.indexOf('/unmember-login') !== -1 ||
          currentPath.indexOf('up') !== -1 ? (
            <>
              <HomeIcon
                className={classes.selectedIcon}
                sx={{ fontSize: iconSize }}
              />
              <div className={classes.selectedCharacter}>ホーム</div>
            </>
          ) : (
            <>
              <HomeOutlinedIcon
                sx={{ fontSize: iconSize }}
                className={classes.unselectedIcon}
              />
              <div className={classes.unselectedCharacter}>ホーム</div>
            </>
          )}
        </div>

        <div
          style={{ textAlign: 'center', width: '25%' }}
          onClick={() => navigate('/logs/calendar')}
        >
          {((currentPath.indexOf('/logs') !== -1 ||
            currentPath.indexOf('/result') !== -1) && (
            <>
              <DateRangeIcon
                className={classes.selectedIcon}
                sx={{ fontSize: iconSize }}
              />
              <div className={classes.selectedCharacter}>練習のきろく</div>
            </>
          )) || (
            <>
              <DateRangeIcon
                className={classes.unselectedIcon}
                sx={{ fontSize: iconSize }}
              />
              <div className={classes.unselectedCharacter}>練習のきろく</div>
            </>
          )}
        </div>

        <div
          style={{ textAlign: 'center', width: '25%' }}
          onClick={handleTrialClick}
        >
          {currentPath === '/practice' ? (
            <>
              <FitnessCenterIcon
                className={classes.selectedIcon}
                sx={{ fontSize: iconSize }}
              />
              <div className={classes.selectedCharacter}>トレーニング</div>
            </>
          ) : (
            <>
              {onboardState !== 'beforePractice' && (
                <>
                  <FitnessCenterOutlinedIcon
                    className={classes.unselectedIcon}
                    sx={{ fontSize: iconSize }}
                  />
                  <div className={classes.unselectedCharacter}>
                    トレーニング
                  </div>
                </>
              )}
              {onboardState === 'beforePractice' && (
                <>
                  <Badge variant="dot" color="warning">
                    <FitnessCenterOutlinedIcon
                      className={classes.unselectedIcon}
                      sx={{ fontSize: iconSize }}
                    />
                  </Badge>
                  <div className={classes.badgeCharacter}>トレーニング</div>
                </>
              )}
            </>
          )}
        </div>

        <div
          style={{ textAlign: 'center', width: '25%' }}
          onClick={handleVideoList}
        >
          {currentPath === '/announce' ||
          currentPath.indexOf('/announce') !== -1 ? (
            <>
              <ContactSupportIcon
                className={classes.selectedIcon}
                sx={{ fontSize: iconSize }}
              />
              <div className={classes.selectedCharacter}>サポート</div>
            </>
          ) : (
            <>
              <ContactSupportOutlinedIcon
                sx={{ fontSize: iconSize }}
                className={classes.unselectedIcon}
              />
              <div className={classes.unselectedCharacter}>サポート</div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};
