import { PostureIntro, Senobi } from 'components/MenuConst/menus/grade_0/10kyu_trac';
import { SuriSuriWalk } from 'components/MenuConst/menus/grade_4/6kyu_trac';
import { MountainClimber, MountainWalker } from 'components/MenuConst/menus/grade_5/5kyu_trac';
import { islandType } from 'features/selectable_course/types';
import {
  ChairJump,
  FourWalk,
  GooChokiJump,
  GooPahJump,
  PahChokiJump,
  SevenWalk,
  SplitJump,
  SwingJump,
  ToeSideWalk,
} from './practiceMenus';

const decidePracticeMenuForContinent = (island: islandType) => {
  switch (island) {
    case 'start':
      return [PostureIntro, Senobi, SuriSuriWalk];
    case 'europe':
      return [SwingJump];
    case 'america':
      return [MountainWalker, MountainClimber, SplitJump];
    case 'asia':
      return [FourWalk, SevenWalk];
    case 'oceania':
      return [GooPahJump, PahChokiJump, GooChokiJump];
    case 'africa':
      return [ChairJump, ToeSideWalk];

    default:
      throw new Error('Invalid input');
  }
};

export default decidePracticeMenuForContinent;
