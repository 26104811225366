import React, { useState, useEffect, useCallback, memo, FC } from 'react';
import { getAllVideoByPageAdapter, getExamVideoByPageAdapter } from 'adapters/getVideosAdapter';
import { VideoCard } from 'features/scoring/components/VideoCardForCheckPage';
import { RunnerType, VideoType } from 'adapters/repositories/typeDefinition';
import { ScoreEditField } from 'features/scoring/components/ScoreEditField';
import { SearchVideoBar } from 'pages/adminPage/common/SearchVideoBar';
import {
  ChosenVideoType,
  filterConditionType,
  FilterType,
  filterConditionKey,
} from 'features/scoring/types';
import { FilterConditionSetter } from 'features/scoring/components/FilterConditionSetter';

type BossCheckProps = {
  runner: RunnerType | undefined;
};

export const BossCheckPage: FC<BossCheckProps> = memo(({ runner }) => {
  const [allVideos, setAllVideos] = useState<VideoType[]>([]);
  const [videoToShow, setVideoToShow] = useState<VideoType[]>([]);
  const [chosenVideo, setChosenVideo] = useState<ChosenVideoType>(null);
  const [filterCondition, setFilterCondition] = useState<filterConditionType>({
    filter: 'onlyExam',
    page: 0,
    limit: 20,
  });
  const [videoAmount, setVideoAmount] = useState<number>(0);
  const student_group = (!!runner && runner?.student_group) || 'admin';
  const handleChangeFilterType = useCallback(
    (key: filterConditionKey) => (c: FilterType | number) => {
      setFilterCondition({ ...filterCondition, [key]: c });
    },
    [filterCondition],
  );

  useEffect(() => {
    const offSet = filterCondition.limit * filterCondition.page;
    if (filterCondition.filter === 'all') {
      getAllVideoByPageAdapter(filterCondition.limit, offSet).then((response) => {
        console.log(response);
        setVideoAmount(response['count']);
        setAllVideos(response['results']);
        setVideoToShow(response['results']);
      });
      return;
    }
    getExamVideoByPageAdapter(filterCondition.limit, offSet).then((response) => {
      console.log(response);
      setVideoAmount(response['count']);
      setAllVideos(response['results']);
      setVideoToShow(response['results']);
    });
  }, [filterCondition]);

  const fetchVideos = useCallback(() => {
    console.log(filterCondition);
    const offSet = filterCondition.limit * filterCondition.page;
    getExamVideoByPageAdapter(filterCondition.limit, offSet).then((response) => {
      console.log(response);
      setVideoAmount(response['count']);
      setAllVideos(response['results']);
      setVideoToShow(response['results']);
    });
  }, [filterCondition]);

  const onClickVideo = (v: VideoType) => () => {
    setChosenVideo(v);
    console.log(v);
    const viewportWidth: number = window.innerHeight;

    // 60vwに相当するピクセル数を計算
    const scrollAmount: number = viewportWidth * 0.6;

    window.scrollBy({
      top: scrollAmount,
      behavior: 'smooth', // スムーズなスクロール動作
    });
  };

  return (
    <>
      {' '}
      <div
        style={{
          fontWeight: 'bold',
          textAlign: 'center',
          fontSize: '14pt',
          padding: '12px',
        }}
      >
        管理者による承認画面
      </div>
      <SearchVideoBar videoList={allVideos} setVideoToShow={setVideoToShow} />
      <div style={{ margin: '10px 0px 10px 11px' }}>
        <FilterConditionSetter
          filterCondition={filterCondition}
          handleChangeFilterType={handleChangeFilterType}
          count={videoAmount}
        />
      </div>
      <div
        style={{
          margin: '3px',
          overflowY: 'scroll',
          height: `${window.parent.screen.height * 0.5}px`,
          border: '3px solid #EEEEEE',
        }}
      >
        {videoToShow && videoToShow.length > 0
          ? videoToShow.map((vObj, idx) => {
              return <VideoCard key={idx} video={vObj} onClickFunc={onClickVideo} />;
            })
          : null}
      </div>
      <div id="scoringArea">
        <>
          <br />
          {!!chosenVideo && <ScoreEditField chosenVideo={chosenVideo} fetchVideos={fetchVideos} />}
        </>
      </div>
    </>
  );
});
