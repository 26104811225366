import { Box } from '@material-ui/core';
import {
  continentToJenreDict,
  convertContinentName,
} from 'features/selectable_course/funcs/convertIslandName';
import { islandType } from 'features/selectable_course/types';
import React, { FC, memo } from 'react';
import { decideImgForExamScreen } from './func/decideImg';
import classes from './examscreen.module.css';
import iiduka from 'features/selectable_course/static/onishi.png';
import fukidashi from 'features/selectable_course/static/comment.png';
import { continentMenuDict } from 'features/selectable_training/menus/menuConst';
import { ExamMenus } from './ExamMenus';
import { IconButton } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { allContinentList } from 'constants/nations';
import { useNavigate } from 'react-router-dom';
import { nationImgDict } from 'features/selectable_course/constants/continentCountries';

type Props = {
  island: string | undefined;
};
const ExamScreen: FC<Props> = memo(({ island }) => {
  const navigate = useNavigate();

  const continent = (island as islandType) || ('start' as islandType);
  const continentNum = allContinentList.length;
  const imgForTheContinent = decideImgForExamScreen(continent);
  const examMenus = continentMenuDict[continent];
  const islandIdx = allContinentList.findIndex((c) => c === island);
  const nextContinent = allContinentList[(islandIdx + 1) % continentNum];
  const beforeContinent = allContinentList[(islandIdx + continentNum - 1) % continentNum];

  return (
    <>
      <Box style={{ textAlign: 'center' }}>
        <div style={{ display: 'flex', width: '95vw', textAlign: 'center' }}>
          <div style={{ width: '18vw' }}>
            <IconButton
              onClick={() => {
                navigate(`/selectable/training/${beforeContinent}`);
              }}
            >
              <ArrowBackIcon />
            </IconButton>
          </div>
          <div style={{ width: '59vw', marginTop: '1vw', fontSize: '1.2rem', fontWeight: 'bold' }}>
            ~ {convertContinentName(continent)} ~
          </div>
          <div style={{ width: '18vw' }}>
            <IconButton
              onClick={() => {
                navigate(`/selectable/training/${nextContinent}`);
              }}
            >
              <ArrowForwardIcon />
            </IconButton>
          </div>
        </div>

        <div className={classes.continentWrapper}>
          <div style={{ marginLeft: '8vw' }}>
            <img src={imgForTheContinent} alt="大陸" width={'85%'} />
          </div>
          <div>
            {continent === 'start' && (
              <>
                <div className={classes.tokyoPosition}>
                  <img src={nationImgDict['東京']} alt="大陸" width={'100%'} />
                </div>
                <div className={classes.sapporoPosition}>
                  <img src={nationImgDict['札幌']} alt="大陸" width={'100%'} />
                </div>
                <div className={classes.kyotoPosition}>
                  <img src={nationImgDict['京都']} alt="大陸" width={'100%'} />
                </div>
              </>
            )}
            {continent === 'asia' && (
              <>
                <div className={classes.chinaPosition}>
                  <img src={nationImgDict['中国']} alt="大陸" width={'100%'} />
                </div>
                <div className={classes.indiaPosition}>
                  <img src={nationImgDict['インド']} alt="大陸" width={'100%'} />
                </div>
                <div className={classes.qatarPosition}>
                  <img src={nationImgDict['カタール']} alt="大陸" width={'100%'} />
                </div>
              </>
            )}
            {continent === 'africa' && (
              <>
                <div className={classes.moroccoPosition}>
                  <img src={nationImgDict['モロッコ']} alt="大陸" width={'100%'} />
                </div>
                <div className={classes.southAfricaPosition}>
                  <img src={nationImgDict['南アフリカ']} alt="大陸" width={'100%'} />
                </div>
                <div className={classes.kenyaPosition}>
                  <img src={nationImgDict['ケニア']} alt="大陸" width={'100%'} />
                </div>
              </>
            )}
            {continent === 'europe' && (
              <>
                <div className={classes.britainPosition}>
                  <img src={nationImgDict['イギリス']} alt="大陸" width={'100%'} />
                </div>
                <div className={classes.norwayPosition}>
                  <img src={nationImgDict['ノルウェー']} alt="大陸" width={'100%'} />
                </div>
                <div className={classes.germanyPosition}>
                  <img src={nationImgDict['ドイツ']} alt="大陸" width={'100%'} />
                </div>
              </>
            )}
            {continent === 'america' && (
              <>
                <div className={classes.americaPosition}>
                  <img src={nationImgDict['アメリカ']} alt="大陸" width={'100%'} />
                </div>
                <div className={classes.canadaPosition}>
                  <img src={nationImgDict['カナダ']} alt="大陸" width={'100%'} />
                </div>
                <div className={classes.jamaicaPosition}>
                  <img src={nationImgDict['ジャマイカ']} alt="大陸" width={'100%'} />
                </div>
              </>
            )}
            {continent === 'oceania' && (
              <>
                <div className={classes.papuaPosition}>
                  <img src={nationImgDict['パプアニューギニア']} alt="大陸" width={'100%'} />
                </div>
                <div className={classes.nzlPosition}>
                  <img src={nationImgDict['ニュージーランド']} alt="大陸" width={'100%'} />
                </div>
                <div className={classes.australiaPosition}>
                  <img src={nationImgDict['オーストラリア']} alt="大陸" width={'100%'} />
                </div>
              </>
            )}
          </div>
        </div>
      </Box>
      <div className={classes.iidukaZ}>
        <div style={{ width: '32vw', padding: '1vw', marginTop: '-33vw' }}>
          <img src={iiduka} alt="map" width="100%" />
        </div>
        <div className={classes.fukidashiWrapper}>
          <div className={classes.fukidashiImg}>
            <img src={fukidashi} width="100%" alt="comment" />
          </div>
          <div className={classes.fukidashiContent}>
            {convertContinentName(continent)}の{continent === 'start' ? '都市' : '国々'}をまわって
            <br />
            速く走るための
            <br />「{continentToJenreDict[continent]}」を身につけよう！
          </div>
        </div>
      </div>
      <ExamMenus menus={examMenus} />
      <div style={{ marginTop: '20vw' }}>
        <br />
      </div>
    </>
  );
});

export default ExamScreen;
