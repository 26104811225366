import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { SelectableTopView } from './SelectableTopView';
import { idatenWorldWrapper } from '../common/idatenWorldWrapper';
import TrainingPage from 'features/selectable_training';
import AchievementsPage from 'features/achievementScreen';
import AskPage from 'features/askpage';
import UploadScreen from 'features/selectable_upload';
import SelectableLogPage from 'features/selectable_logs';
import { ResultJoukyuPage } from 'features/selectable_result';
import UsagePage from 'features/selectable_usage';

export const SelectableCourseRoutes = () => {
  return (
    <Routes>
      <Route
        path="training/:island"
        element={idatenWorldWrapper({
          children: <TrainingPage />,
        })}
      />
      <Route
        path="result/:videoId"
        element={idatenWorldWrapper({
          children: <ResultJoukyuPage />,
        })}
      />
      <Route
        path="achievements"
        element={idatenWorldWrapper({
          children: <AchievementsPage />,
        })}
      />
      <Route
        path="upload"
        element={idatenWorldWrapper({
          children: <UploadScreen />,
        })}
      />
      <Route
        path="ask"
        element={idatenWorldWrapper({
          children: <AskPage />,
        })}
      />
      <Route
        path="videolist"
        element={idatenWorldWrapper({
          children: <SelectableLogPage />,
        })}
      />
      <Route
        path="usage"
        element={idatenWorldWrapper({
          children: <UsagePage />,
        })}
      />
      <Route
        path="*"
        element={idatenWorldWrapper({
          children: <SelectableTopView />,
        })}
      />
    </Routes>
  );
};
