import React from 'react';
import { RouteObject } from 'react-router-dom';
import { ScoringRoutes } from 'features/scoring/routes';
import { AuthRoutes } from 'features/auth/routes';
import { VideoLogRoutes } from 'features/videoLogs/routes';
import { SelectableCourseRoutes } from 'features/selectable_course/routes';
import { VideoUploadRoutes } from 'features/upload/routes';

export const routes: RouteObject[] = [
  {
    path: '/scoring/*',
    element: <ScoringRoutes />,
  },
  {
    path: '/auth/*',
    element: <AuthRoutes />,
  },
  {
    path: '/logs/*',
    element: <VideoLogRoutes />,
  },
  {
    path: '/upload/*',
    element: <VideoUploadRoutes />,
  },
  {
    path: '/selectable/*',
    element: <SelectableCourseRoutes />,
  },
];
