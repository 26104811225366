import React, { FC } from 'react';
import { AccountContext } from 'contexts/AccountContext';
import { PageTitle } from '../../components/common/PageTitle';
import { StickerModal } from '../../components/Sticker/StickerModal';
import { UploadStepViewer } from 'pages/UploadPage/components/UploadStepViewer';
import { goToTags } from 'pages/UploadPage/func';
import { TrialUploadButton } from './TrialUploadButtonWithProgress';
import { TextInput } from 'pages/UploadPage/components/TextInput';
import { UploadNotification } from 'components/Notifications';
import classes from './trial.module.css';

import { HOST } from 'v1apiRequest';
import { YoutubeVideoPlayer } from 'utils/components/YoutubeVideoPlayer';
import { convertRunnerGrade } from 'components/constants';

export type alertStatusType = {
  open: boolean;
  status: 'OK' | 'ERROR' | 'default';
};

type inputEvent = React.ChangeEvent<HTMLInputElement>;
export type handleInputChange = (e: inputEvent) => void;

const createObjectURL =
  (window.URL || window.webkitURL).createObjectURL ||
  //eslint-disable-next-line
  (window as any).createObjectURL;

type FileType = File | null;

export const TrialUploadPage: FC = () => {
  const [file, setFile] = React.useState<FileType>(null);

  const { accountInfo, currentRunner } = React.useContext(AccountContext);
  const [videoSrc, setVideoSrc] = React.useState(null);
  const [isUploading, setIsUploading] = React.useState(false);
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [activeStep, setActiveStep] = React.useState(0);
  const [formContent, setFormContent] = React.useState('');
  const [alertStatus, setAlertStatus] = React.useState<alertStatusType>({
    open: false,
    status: 'default',
  });

  const handleFormChange: handleInputChange = (e) => {
    setFormContent(e.target.value);
    if (e.target.value.length < 1 && activeStep === 2) {
      setActiveStep(1);
      return;
    }
    if (!file) {
      setActiveStep(0);
      return;
    }
    setActiveStep(2);
  };

  const chooseVideo = React.useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    // ファイル選択がキャンセルされた時は undefined
    if (!e.currentTarget.files) return;

    const uploadedFile = e.currentTarget.files[0];
    const videoUrl = createObjectURL(uploadedFile);
    console.log(uploadedFile);
    setFile(uploadedFile);
    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '() => string | null' is not assi... Remove this comment to see the full error message
    setVideoSrc(() => {
      return videoUrl ? videoUrl : null;
    });
    setActiveStep(1);
    goToTags();
  }, []);

  if (!currentRunner || !accountInfo) {
    return <>ランナー情報なし</>;
  }

  const params = new FormData();
  if (!!file && activeStep >= 2) {
    const croudFrontUrl = HOST.split('/api/')[0];
    params.append('content', file);
    params.append('fileName', file.name);
    params.append('uploaded_by', accountInfo.id);
    params.append('upload_runner', currentRunner.id);
    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'boolean' is not assignable to pa... Remove this comment to see the full error message
    params.append('is_exam', false);
    params.append('video_type', 'diagnose');
    params.append('cloudfront_url', croudFrontUrl);
    params.append('text', formContent);
    params.append('menuText', 'かけっこ診断');
  }

  const sendCompleteFuncs = () => {
    setActiveStep(0);
    setFile(null);
    setVideoSrc(null);
    setAlertStatus({ open: true, status: 'OK' });
    setIsModalOpen(true);
    // setIsModalOpen(true);
  };

  const sendFailFuncs = () => {
    console.log('upload失敗');
    setIsUploading(false);
    setAlertStatus({ open: true, status: 'ERROR' });
  };

  const reibun = '（例）ほんきで走れたと思う。';

  return (
    <div>
      <PageTitle title={'かけっこ診断'} />
      <UploadNotification alertStatus={alertStatus} setAlertStatus={setAlertStatus} />

      <div
        style={{
          textAlign: 'center',
          padding: '5px',
        }}
      >
        <UploadStepViewer activeStep={activeStep} />

        <YoutubeVideoPlayer srcURL="https://www.youtube.com/embed/Mf0DWNRPVUM" />

        <StickerModal
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          grade={convertRunnerGrade(currentRunner)}
        />

        <div>
          <div style={{ marginTop: '20px' }}>
            <label className={classes.inputwrapper}>
              挑戦する動画を選ぶ
              <input
                type="file"
                accept="video/*"
                onChange={chooseVideo}
                className={classes.labelinput}
              />
            </label>
          </div>
          <div style={{ paddingTop: '10px' }}>
            {file ? (
              <div>
                <video
                  id="v"
                  // @ts-expect-error ts-migrate(2322) FIXME: Type 'null' is not assignable to type 'string | un... Remove this comment to see the full error message
                  src={videoSrc}
                  height="120px"
                  width="200px"
                  muted
                ></video>
                <canvas id="c" width="0px" height="0px" />
              </div>
            ) : (
              <div></div>
            )}
          </div>

          <div
            style={{
              textAlign: 'center',
              padding: '10px',
              marginTop: '30px',
            }}
            id="tags"
          >
            <>
              <TextInput
                title="✏️走った感想を書いてみよう！"
                reibun={reibun}
                value={formContent}
                onChange={handleFormChange}
              />
            </>
          </div>

          <TrialUploadButton
            isUploading={isUploading}
            activeStep={activeStep}
            file={file}
            params={params}
            completeFunc={sendCompleteFuncs}
            failFunc={sendFailFuncs}
            formContent={formContent}
            color="primary"
          />

          <div style={{ marginTop: '360px' }}></div>
        </div>
      </div>
    </div>
  );
};
