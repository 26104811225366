import React, { FC, useCallback } from 'react';
import { ModalWrapper } from 'components/modals/ModalWrapper';

import { islandExamType } from 'features/selectable_training/types';

const useSingleMenuWatchModal = (menu: islandExamType | null) => {
  const [open, setOpen] = React.useState(false);

  const toggleModalOpen = () => {
    setOpen(!open);
  };

  const forceModalOpen = useCallback((openOrClose: boolean) => {
    setOpen(openOrClose);
  }, []);

  return {
    forceModalOpen: forceModalOpen,
    toggleModalOpen: toggleModalOpen,
    MenuWatchModal: (menu && (
      <MenuWatchModal isModalOpen={open} setIsModalOpen={setOpen} menu={menu} />
    )) || <></>,
  };
};

type Props = {
  isModalOpen: boolean;
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  menu: islandExamType;
};

const MenuWatchModal: FC<Props> = ({ isModalOpen, setIsModalOpen, menu }) => {
  return (
    <ModalWrapper isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen}>
      <div>
        <div style={{ margin: '2px 0 12px 0' }}>
          <SingleMenuViewer exam={menu} />
        </div>
      </div>
    </ModalWrapper>
  );
};

type SingleMenuViewerProps = {
  exam: islandExamType;
};

const SingleMenuViewer: FC<SingleMenuViewerProps> = ({ exam }) => {
  return (
    <>
      <div style={{ textAlign: 'center', marginTop: '1px', overflowY: 'scroll' }}>
        <span style={{ fontWeight: 'bold', fontSize: '12pt' }}>{exam.title}の説明</span>
        <div>{exam?.explanation || <></>}</div>
        <div style={{ margin: '10px 4px 20px 2px', width: '94vw', height: '60vw' }}>
          <iframe
            width="94%"
            height="94%"
            src={exam.videoLink + '?rel=0&modestbranding=1'}
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            style={{ marginBottom: '8px' }}
          ></iframe>
        </div>
        <div
          style={{
            margin: '-2px 0 10px 0',
            fontSize: '10pt',
            color: '#424242',
          }}
        >
          （青枠外をタップするとメニューの説明を閉じます）
        </div>
      </div>
    </>
  );
};

export default useSingleMenuWatchModal;
