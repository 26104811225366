import React, { useState } from 'react';
import { UploadStepViewer } from 'pages/LevelUpPage/components/UploadStepViewer';

const defaultSteps = ['動画を選ぶ', 'チェック & メモ', '送信'];

export const useActiveStep = (steps: string[] = defaultSteps) => {
  const [activeStep, setActiveStep] = useState<number>(0);
  return {
    activeStep: activeStep,
    UploadStepViewer: (
      <UploadStepViewer activeStep={activeStep} steps={steps} />
    ),
    setActiveStep: setActiveStep,
  };
};
