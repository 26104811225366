import { Card, CircularProgress } from '@mui/material';
import { getVideoByIdAdapter } from 'adapters/getVideosAdapter';
import { VideoType } from 'adapters/repositories/typeDefinition';
import { CoachCommentViewer } from 'pages/ResultPage/components/CoachCommentViewer';
import { VideoPlayer } from 'pages/ResultPage/components/VideoPlayer';
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { ScoreViewForTrac } from './components/ScoreViewForTrac';
import { putVideoInfo } from 'v1apiRequest';

type currentVideoType = VideoType | null;

export const ResultJoukyuPage = () => {
  const { videoId } = useParams();
  const [isLoading, setIsLoading] = React.useState(false);
  const [currentVideo, setCurrentVideo] = React.useState<currentVideoType>(null);
  const isFirstLogin = videoId === 'first-grade';

  // 動画のIDを元にビデオを持ってくる
  React.useEffect(() => {
    if (isFirstLogin || !videoId) {
      return;
    }
    setIsLoading(true);
    getVideoByIdAdapter(videoId).then((video) => {
      setCurrentVideo(video[0]);
      setIsLoading(false);
    });
  }, [videoId]);

  useEffect(() => {
    if (!!currentVideo && !!currentVideo.score) {
      putVideoInfo(currentVideo.id, { is_seen: true });
    }
    return;
  }, [currentVideo]);

  if (!currentVideo) {
    return (
      <div style={{ width: '100%', textAlign: 'center', marginTop: '15vw' }}>
        <CircularProgress />
      </div>
    );
  }

  const score = currentVideo.score;

  return (
    <div>
      {(currentVideo && <VideoPlayer isLoading={isLoading} videoInfo={currentVideo} />) || (
        <div style={{ width: '100%', textAlign: 'center', marginTop: '15vw' }}>
          <CircularProgress />
        </div>
      )}

      {(!score || !currentVideo.checked_by_boss) && (
        <div>
          <div
            style={{
              padding: '5px',
              borderRadius: '10px',
              backgroundColor: '#d4d9df',
              margin: '14px',
              fontWeight: 'bold',
            }}
          >
            現在動画の添削待ちです。
          </div>
        </div>
      )}

      {!!score && currentVideo.checked_by_boss && (
        <div>
          <Card
            variant="outlined"
            style={{
              padding: '6px',
              marginTop: '20px',
            }}
          >
            <div
              style={{
                marginBottom: '6px',
                fontWeight: 'bold',
                fontSize: '12pt',
                color: '#808080',
              }}
            >
              {' '}
              今回のせいせき
            </div>
            <ScoreViewForTrac video={currentVideo} />
          </Card>

          <CoachCommentViewer video={currentVideo} />
        </div>
      )}
      <div style={{ marginTop: '20vw' }}>
        <br />
      </div>
    </div>
  );
};
