import { useEffect, useState } from 'react';
import Papa from 'papaparse';
import { nationType } from 'constants/nations';

export const spSheetURL =
  'https://docs.google.com/spreadsheets/d/e/2PACX-1vT-FjtZITMAgFyjfqwEggttPZhwwaCmi7t7EN-6TTQ51AWNm5cLsM36c2FFUKrlyrNEuyuab3LF05kY/pub?gid=77805607&single=true&output=csv';

export const useReadSpreadSheet = () => {
  const [data, setData] = useState<unknown>(undefined);
  useEffect(() => {
    console.log('fetch');
    Papa.parse(spSheetURL, {
      download: true,
      header: true,
      complete: (results) => {
        setData(convertKeys(results.data as spSheetData[]));
      },
    });
  }, []);

  if (!data) {
    return undefined;
  }

  return data;
};

type spSheetData = {
  コメント: string;
  チェックポイント: string;
  国名: nationType;
  練習: string;
  選択肢: string;
  評価: string;
};

type outputType = {
  [key in nationType]: spSheetData[];
};

export type returnDictType = {
  [key in nationType]: {
    optionDict: {
      [options in string]: string[];
    };
    nobishiroData: {
      [options in string]: string;
    };
    complimentData: {
      [options in string]: string;
    };
    checkPoints: string[];
    shumoku: string;
  };
};

///////////////////////
// 以下のようなJSONを作るのがゴール
// const result = {
//   インド: {
//     optionDict: {
//       かかとがお尻の下にくる: ['◯良い', '×踵が前に出ている',],
//       足を下ろす: [
//         '◯良い',
//         '×足を上げようとしている',
//         '×つま先が下がっている',
//         '×上げすぎている',
//         '×低すぎる',
//       ],
//       地面についている方の脚: ['◯膝が曲がっていない(許容範囲内)', '×大きく曲がっている'],
//       うでふり: ['◯できている', '×大きすぎる', '×小さすぎる'],
//     },
//     nobishiroDict: {
//       '×踵が前に出ている':
//         'かかとが前に出てしまっている点があります。進もうとせず~',
//     },
//     compliments: {
//       足を下ろす: 'かかとが支持脚の膝の高さまでしっかり上がっていますね！脚は',
//     },
//   },
//  中国: {
//      |
//    }
// };
/////////////////////

const convertKeys = (data: spSheetData[]) => {
  console.log(data);
  let nationObj: outputType = {} as outputType;
  data.forEach((d) => {
    if (Object.keys(nationObj).includes(d['国名'])) {
      nationObj[d['国名']].push(d);
    } else {
      nationObj[d['国名']] = [d];
    }
  });

  // 続いてoptionDictなどを作っていく
  let retObj = {} as unknown;
  Object.keys(nationObj).forEach((nation) => {
    const country = nation as nationType;
    const commentListByNation = nationObj[country];

    // 以下はoptionDictの作成
    const optionDict = {} as {
      [key in nationType]: unknown;
    };
    const checkpoints = []; // 辞書化しちゃうと順番が狂うので直す
    commentListByNation.map((d) => {
      if (Object.keys(optionDict).includes(d['チェックポイント'])) {
        optionDict[d['チェックポイント']].push(d['評価']);
      } else {
        optionDict[d['チェックポイント']] = [d['評価']];
        checkpoints.push(d['チェックポイント']);
      }
    });

    // 以下はnobishiroDictの作成
    const nobishiroData = {} as unknown;
    commentListByNation
      .filter((d) => d['評価'].includes('×') || d['評価'].includes('△'))
      .map((d) => {
        nobishiroData[d['評価']] = d['コメント'];
      });

    // 以下はcomplimentsの作成
    const complimentData = {} as unknown;
    commentListByNation
      .filter((d) => d['評価'].includes('◯'))
      .map((d) => {
        complimentData[d['チェックポイント']] = d['コメント'];
      });
    // 種目を入れる
    const shumoku = commentListByNation[0]['練習'];

    retObj[nation] = { optionDict, complimentData, nobishiroData, checkpoints, shumoku };
  });

  console.log(retObj);

  return retObj;
};
