import { menuType } from 'pages/PracticePage/type';
import kabe_renzoku_thumb from 'components/MenuConst/thumbnails/kabe_renzoku_thumb.png';
import three_sec_dash_thumb from 'components/MenuConst/thumbnails/3sec_dash_thumb.png';
import { kabe_renzoku, three_sec_dash } from './videos';

export const KabeRenzoku: menuType = {
  duration: 5, // 所要時間（分）を入れる
  title: '連続壁おし',
  isInside: 'able',
  target: ['足の動き', 'スタート'],
  menuVariation: ['tech'],
  hitWordList: ['連続壁押し', 'れんぞくかべおし'],
  grade: 2,
  image: kabe_renzoku_thumb,
  video: kabe_renzoku,
};

export const ThreeSecDashTest: menuType = {
  duration: 5, // 所要時間（分）を入れる
  title: '3秒ダッシュ #昇級テスト',
  isInside: 'unable',
  target: ['しせい', '足の動き', 'スタート'],
  menuVariation: ['tech'],
  hitWordList: ['3秒ダッシュ', 'さんびょうだっしゅ'],
  grade: 2,
  image: three_sec_dash_thumb,
  video: three_sec_dash,
};
