import React, { FC } from 'react';
import { PageTitle } from 'components/common/PageTitle';
import { Box, Card } from '@material-ui/core';
import { ToDos } from 'components/common/Elements/ToDos';

const AskPage: FC = () => {
  const lineLink = 'https://lin.ee/GYFMyey';
  return (
    <>
      <PageTitle title={'個別相談ページ'} />
      <div style={{ margin: '16px' }}>
        個別のご相談は
        <a href={lineLink}>公式LINEアカウント</a>
        より承っております。
        <br />
        使い方に関するご質問以外にも、たとえば以下の内容についてもご相談が可能です。ご遠慮なくご利用ください！
      </div>
      <Card
        style={{
          fontSize: '12pt',
          padding: '6px',
          backgroundColor: '#e6ffe9',
          color: '#424242',
        }}
      >
        <Box>
          <ToDos title="例1：かけっこ直接指導の相談" />
          <div style={{ marginLeft: '30px', marginTop: '6px' }}>
            <div style={{ marginTop: '8px', color: '#000000' }}>
              専属コーチからの直接指導をご希望の方は公式LINEアカウントから「直接指導してほしい」旨をご相談ください！
            </div>
          </div>
        </Box>
        <Box style={{ marginTop: '8px' }}>
          <ToDos title="例2：TRAC入会に関する相談" />
          <div style={{ marginLeft: '30px', marginTop: '6px' }}>
            <div style={{ marginTop: '8px', color: '#000000' }}>
              検討中、まずは話だけ聞きたい、という方も大歓迎です！
            </div>
          </div>
        </Box>
      </Card>
    </>
  );
};

export default AskPage;
