export type nationType =
  | 'インド'
  | '中国'
  | 'カタール'
  | 'カナダ'
  | 'アメリカ'
  | 'ジャマイカ'
  | 'ドイツ'
  | 'ノルウェー'
  | 'イギリス'
  | 'モロッコ'
  | '南アフリカ'
  | 'ケニア'
  | 'パプアニューギニア'
  | 'ニュージーランド'
  | 'オーストラリア'
  | '札幌'
  | '東京'
  | '京都';

export type continentType = 'oceania' | 'africa' | 'asia' | 'europe' | 'america' | 'start';

export const allContinentList = ['start', 'asia', 'africa', 'europe', 'america', 'oceania'];

export const continentWithExam: continentType[] = [
  'oceania',
  'africa',
  'asia',
  'europe',
  'america',
];

type continentNationDictType = {
  [key in continentType]: nationType[];
};

export const continentNationDict: continentNationDictType = {
  oceania: ['パプアニューギニア', 'ニュージーランド', 'オーストラリア'],
  africa: ['モロッコ', '南アフリカ', 'ケニア'],
  asia: ['中国', 'インド', 'カタール'],
  europe: ['ドイツ', 'ノルウェー', 'イギリス'],
  america: ['カナダ', 'アメリカ', 'ジャマイカ'],
  start: ['札幌', '東京', '京都'],
};

export const continentColorDict = {
  oceania: '#00bcd4',
  africa: '#000000',
  asia: '#ff0000',
  europe: '#008000',
  america: '#ffd700',
  start: '#b8f5de',
};
