import React from 'react';
import { VideoType } from 'adapters/repositories/typeDefinition';
import VideoUploadButtonByS3 from 'features/upload/components/atoms/UploadButtonWithProgress';

type Props = {
  videos: VideoType[] | undefined;
  params: FormData;
  activeStep: number;
  completeFunc: () => void;
  formContent: string;
  failFunc: () => void;
};
export const ReplaceVideoView = (props: Props) => {
  const { videos = [], params, activeStep, completeFunc, formContent, failFunc } = props;
  const examVideo = detectReplaceOrNot(videos) as VideoType;
  console.log(props);

  if (!examVideo) {
    return (
      <VideoUploadButtonByS3
        title="この動画で挑戦"
        activeStep={activeStep}
        color="secondary"
        completeFunc={completeFunc}
        failFunc={failFunc}
        params={params}
        formContent={formContent}
      />
    );
  }
  if (!!examVideo.thumbnail)
    return (
      <div style={{ paddingTop: '20px' }}>
        <img width="200px" alt="" src={`${examVideo.thumbnail}`} />
        <div
          style={{
            padding: '12px',
            textAlign: 'left',
          }}
        >
          下のボタンを押すと昇級にチャレンジする動画が
          <span
            style={{
              fontSize: '12pt',
              color: 'red',
              fontWeight: 'bold',
            }}
          >
            ↑
          </span>
          の動画から今回選択したものに変更されますがよろしいでしょうか？
        </div>

        <VideoUploadButtonByS3
          title="動画を変更"
          activeStep={activeStep}
          color="secondary"
          completeFunc={completeFunc}
          failFunc={failFunc}
          params={params}
          formContent={formContent}
        />
      </div>
    );
  return <></>;
};

// もし挑戦中の動画があればその動画を返す
function detectReplaceOrNot(videos: VideoType[]) {
  const examVideos = videos.filter((video) => video.is_exam && video.analysis_status !== 'done');
  if (examVideos.length > 0) return examVideos[0];
  return false;
}
