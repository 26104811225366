import React, { FC } from 'react';
import classes from './guidanceTools.module.css';
import { useNavigate } from 'react-router-dom';
import { usePopover } from 'hooks/usePopover';
import { LetterPopover } from 'pages/MemberHome/components/GuidanceTools/popovers/LetterPopover';
import EmailTwoToneIcon from '@mui/icons-material/EmailTwoTone';
import { VideoType } from 'adapters/repositories/typeDefinition';

type Props = {
  examVideo: VideoType | undefined;
};
export const MailBox: FC<Props> = ({ examVideo }) => {
  const navigate = useNavigate();
  const isActive = (examVideo?.checked_by_boss && !examVideo?.is_seen) || false;
  const isBefore = false;
  console.log(examVideo);
  const onClickFunc = () => {
    if (!examVideo || !examVideo.id) {
      return;
    }
    navigate(`/selectable/result/${examVideo.id}`);
  };
  const innerComponent = LetterPopover(isActive, onClickFunc, isBefore);
  const { handleClick, CustomPopover } = usePopover(innerComponent);
  return (
    <>
      <div className={classes.letterPosition}>
        <div onClick={handleClick}>
          {isActive && (
            <div className={classes.shouldBeTouched}>
              <div className={classes.iconContent}>
                <EmailTwoToneIcon
                  style={{
                    fontSize: '16vw',
                    color: '#ff69b4',
                  }}
                />
              </div>
            </div>
          )}
          {!isActive && (
            <div className={classes.disabled}>
              <div className={classes.iconContent}>
                <EmailTwoToneIcon
                  style={{
                    fontSize: '16vw',
                    color: '#db7093',
                  }}
                />
              </div>
            </div>
          )}
        </div>
        {CustomPopover}
      </div>
    </>
  );
};
