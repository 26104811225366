import React, { FC, useContext } from 'react';
import { PageTitle } from 'components/common/PageTitle';
import OlympicMark from './components/OlympicMark';
import { BarGraphCollection } from './components/BarGraph';
import { AccountContext } from 'contexts/AccountContext';
import { completedContriesType } from 'adapters/repositories/typeDefinition';

const AchievementsPage: FC = () => {
  const { currentRunner } = useContext(AccountContext);
  console.log(currentRunner);
  const completed_countries = currentRunner?.completed_countries || ({} as completedContriesType);
  return (
    <div>
      <PageTitle title={'成績ページ'} />
      <OlympicMark countriesDict={completed_countries} />
      <div style={{ marginTop: '55vw', marginLeft: '3vw' }}>
        <BarGraphCollection countriesDict={completed_countries} />
      </div>
      <div style={{ marginTop: '100px' }}>
        <br />
      </div>
    </div>
  );
};

export default AchievementsPage;
