import { menuType } from 'pages/PracticePage/type';
import six_sec_dash_thumb from 'components/MenuConst/thumbnails/six_sec_dash_thumb.png';
import highknee_trac_thumb from 'components/MenuConst/thumbnails/highknee_trac_thumb.png';
import { high_knee_1kyu, six_sec_dash } from './videos';

export const HighKnee1kyu: menuType = {
  duration: 5, // 所要時間（分）を入れる
  title: 'その場ハイニー',
  isInside: 'able',
  target: ['しせい', '足の動き'],
  menuVariation: ['tech'],
  hitWordList: ['その場ハイニー'],
  grade: 2,
  image: highknee_trac_thumb,
  video: high_knee_1kyu,
};

export const SixSecDash: menuType = {
  duration: 5, // 所要時間（分）を入れる
  title: '6秒間ダッシュ #昇級テスト',
  isInside: 'unable',
  target: ['しせい', '全力走'],
  menuVariation: ['tech'],
  hitWordList: ['片足腕上げジャンプ'],
  grade: 2,
  image: six_sec_dash_thumb,
  video: six_sec_dash,
};
