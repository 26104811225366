import React, { FC, memo } from 'react';
import classes from '../onboardingCarousel.module.css';
import gorin_sample from 'features/selectable_course/static/gorin_gif.gif';

const GorinCut: FC = memo(() => {
  return (
    <>
      <div className={classes.blackBorder} style={{ fontSize: '1rem', marginBottom: '6vw' }}>
        クリア数に応じて五輪マークに <br />
        色がつくよ！ 練習を頑張って <br />
        五輪マークを完成させよう！
      </div>
      <div className={classes.iidukaFirstWrapper} style={{ width: '82vw' }}>
        <img src={gorin_sample} alt="" width="100%"></img>
      </div>
    </>
  );
});

export default GorinCut;
