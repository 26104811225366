import { islandType, trainingJenre } from '../types';

export const convertContinentName = (islandName: islandType) => {
  switch (islandName) {
    case 'start':
      return '日本';
    case 'asia':
      return 'アジア';
    case 'america':
      return 'アメリカ';
    case 'europe':
      return 'ヨーロッパ';
    case 'oceania':
      return 'オセアニア';
    case 'africa':
      return 'アフリカ';
    default:
      throw new Error('Invalid input');
  }
};

type continentToJenreType = {
  [key in islandType]: trainingJenre;
};

export const continentToJenreDict: continentToJenreType = {
  start: '走りの基礎',
  asia: 'プッシュ',
  america: 'シザース',
  europe: 'スウィング',
  oceania: 'コンビネーション',
  africa: 'スティフネス',
};
