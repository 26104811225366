import { RunnerType } from 'adapters/repositories/typeDefinition';
import { grades, grades10 } from './MenuConst/GradeGoalConst';

export const MAX_GRADE = 20;

export const calcMaxGrade = (runner: RunnerType) => {
  if (is10GradesPackage(runner)) {
    return 10;
  }
  return 20;
};

export const convertRunnerGrade = (runner: RunnerType) => {
  if (runner.grade > 9) {
    return '0' as grades10;
  }
  if (runner.grade < 0) {
    return '10';
  }
  return String(10 - runner.grade) as grades10;
};

export const convertGradeByRunner = (runner: RunnerType, grade: number) => {
  if (is10GradesPackage(runner)) {
    if (grade > 9) {
      return '0' as grades10;
    }
    return String(10 - grade) as grades10;
  }
  if (grade > 18) return '1' as grades;
  return String(20 - grade) as grades;
};

export function is10GradesPackage(runner: RunnerType) {
  if (!!runner.student_group && ['dps', 'trac', 'newtrac', 'TRAC'].includes(runner.student_group)) {
    return true;
  }
  return true;
}
