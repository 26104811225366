import { ResultPage } from './ResultPage';
import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { LogPage } from './LogPage';
import { withFooter } from 'components/common/footerWrapper';

export const VideoLogRoutes = () => {
  return (
    <Routes>
      <Route
        path="calendar"
        element={withFooter({
          children: <LogPage />,
          currentRunner: undefined,
        })}
      />
      <Route
        path="result/:videoId"
        element={withFooter({
          children: <ResultPage />,
          currentRunner: undefined,
        })}
      />
    </Routes>
  );
};
