import React, { FC, memo, useEffect, useState } from 'react';
import classes from './guidanceTools.module.css';
import { islandType } from 'features/selectable_course/types';
import { useIslandVideoModal } from 'features/selectable_course/hooks/useIslandVideoModal';
import treasure_box from 'static/materials/treasure_box.png';
import treasure_box_open from 'static/materials/treasure_box_open.png';

type Props = {
  island: islandType;
};

export const PlayButton: FC<Props> = memo(({ island }) => {
  const [isBoxOpen, setIsBoxOpen] = useState<boolean>(false);
  const handleClose = () => {
    setIsBoxOpen(false);
    forceModalOpen(false);
  };

  const { forceModalOpen, MessageMovieModal } = useIslandVideoModal(
    island,
    handleClose,
  );
  useEffect(() => {
    setIsBoxOpen(false);
  }, [island]);

  const handleOpen = () => {
    setIsBoxOpen(true);
    setTimeout(() => {
      forceModalOpen(true);
    }, 200);
  };
  return (
    <>
      <div className={classes.treasureBoxPosition}>
        {MessageMovieModal}

        <div className={classes.canTouch}>
          {(isBoxOpen && (
            <div className={classes.treasureBox} onClick={handleClose}>
              <img src={treasure_box_open} alt="" width="70%"></img>
            </div>
          )) || (
            <div className={classes.treasureBox} onClick={handleOpen}>
              <img src={treasure_box} alt="" width="70%"></img>
            </div>
          )}
        </div>
      </div>
    </>
  );
});
