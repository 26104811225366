import React, { FC, memo } from 'react';
import classes from './guidanceTools.module.css';
import FitnessCenterIcon from '@mui/icons-material/FitnessCenter';
import { islandType } from 'features/selectable_course/types';
import { useNavigate } from 'react-router-dom';

type Props = {
  island: islandType;
};

export const Dumbbell: FC<Props> = memo(({ island }) => {
  const navigate = useNavigate();
  const handleClick = () => {
    navigate(`/selectable/training/${island}`);
  };
  return (
    <div className={classes.dumbbellPosition}>
      <div className={classes.canTouch}>
        <div className={classes.iconContent} onClick={handleClick}>
          <FitnessCenterIcon style={{ fontSize: '16vw', color: '#a9a9a9' }} />
        </div>
      </div>
    </div>
  );
});
