import { TabInfo } from 'components/TabInfo';
import React, { FC } from 'react';
import { useParams } from 'react-router-dom';
import ExamScreen from './components/ExamScreen';
import PracticeScreen from './components/PracticeScreen';

const TrainingPagePerIsland: FC = () => {
  const { island } = useParams();

  return (
    <>
      <TabInfo labels={['テスト', '練習']}>
        <>
          <ExamScreen island={island} />
        </>
        <>
          <PracticeScreen island={island} />
        </>
      </TabInfo>
    </>
  );
};

export default TrainingPagePerIsland;
