import React from 'react';
import { RunnerType } from 'adapters/repositories/typeDefinition';
import onishi from 'features/selectable_course/static/onishi.png';

import trac_10 from 'static/target_thumbnail/trac_10.png';
import trac_9 from 'static/target_thumbnail/trac_9.png';
import trac_8 from 'static/target_thumbnail/trac_8.png';
import trac_7 from 'static/target_thumbnail/trac_7.png';
import trac_6 from 'static/target_thumbnail/trac_6.png';
import trac_5 from 'static/target_thumbnail/trac_5.png';
import trac_4 from 'static/target_thumbnail/trac_4.png';
import trac_3 from 'static/target_thumbnail/trac_3.png';
import trac_2 from 'static/target_thumbnail/trac_2.png';
import trac_1 from 'static/target_thumbnail/trac_1.png';
import { convertRunnerGrade } from 'components/constants';
import { grades10 } from 'components/MenuConst/GradeGoalConst';

export const returnThumbnailForGrade = (runner: RunnerType) => {
  const grade = convertRunnerGrade(runner);
  return <img src={tracThumbnails[grade]} alt="" width="99%"></img>;
};

type grade10ThumnbailType = {
  [key in grades10]: string;
};

const tracThumbnails: grade10ThumnbailType = {
  10: trac_10,
  9: trac_9,
  8: trac_8,
  7: trac_7,
  6: trac_6,
  5: trac_5,
  4: trac_4,
  3: trac_3,
  2: trac_2,
  1: trac_1,
  0: onishi,
};
