import React, { FC } from 'react';

import { useWindowSize } from 'hooks/useWindowSize';
import { convertRunnerGrade } from 'components/constants';
import { RunnerType } from 'adapters/repositories/typeDefinition';
import { decideSampleVideoLink } from 'components/MenuConst/VideoLinks/sampleVideoLinks';
import { Box, Typography } from '@mui/material';

type sizeType = number[];

const calculateVideoScreen = (size: sizeType) => {
  return {
    width: Math.trunc(size[0] * 0.85),
    height: Math.trunc(size[0] * 0.582),
  };
};

type Props = {
  runner: RunnerType | undefined;
};

export const LevelUpMenu: FC<Props> = ({ runner }) => {
  const size = useWindowSize();
  const { width, height } = calculateVideoScreen(size);
  if (!runner) {
    return <></>;
  }
  const gradeIdx = convertRunnerGrade(runner);
  console.log(gradeIdx);
  const sampleVideoLink = decideSampleVideoLink(runner);
  console.log(sampleVideoLink);

  if (gradeIdx === '0') {
    return (
      <Box>
        <Typography>1級をクリアした方は「普通に走っている動画」を送ってね！</Typography>

        <Box>
          <iframe
            width={width}
            height={height * 0.9}
            src={sampleVideoLink + '?rel=0&modestbranding=1'}
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            style={{ marginBottom: '8px' }}
          ></iframe>
        </Box>
      </Box>
    );
  }

  return (
    <div>
      <div
        style={{
          textAlign: 'center',
          fontWeight: 'bold',
          padding: '6px',
        }}
      >
        {gradeIdx}級の昇級試験内容👀
      </div>
      <div>
        <iframe
          width={width}
          height={height * 0.9}
          src={sampleVideoLink + '?rel=0&modestbranding=1'}
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          style={{ marginBottom: '8px' }}
        ></iframe>
      </div>
    </div>
  );
};
