import React, { FC, memo } from 'react';

import { VideoType } from 'adapters/repositories/typeDefinition';

import classes from './stopwatch.module.css';

import { RunnerAnimation } from './RunnerAnimation';

type timeViewerProps = {
  video: VideoType;
  name?: string;
};
export const TimeViewer: FC<timeViewerProps> = memo(({ video, name }) => {
  if (!video.score || !video.score.speed) {
    return <p>現在診断中です</p>;
  }

  const runnerSpeed = Number(video.score.real_speed);
  const estTime = calcFiftyTime(runnerSpeed);
  console.log(runnerSpeed, estTime);
  const byo = Math.floor(estTime);
  const syosu = Math.floor((estTime - Math.floor(estTime)) * 100);
  console.log(byo, syosu);

  return (
    <div style={{ textAlign: 'center' }}>
      <div style={{ paddingTop: '20px' }}>
        {(video.upload_runner.username !== 'dummy' && video.upload_runner.username) || name}
        さんの50m予測タイムは
      </div>
      <div className={classes.watchWrapper}>
        <div style={{ marginTop: '10px' }}>
          <RunnerAnimation estTime={estTime} id={video.id} />
        </div>
      </div>
    </div>
  );
});

export const calcFiftyTime = (speed: number) =>
  speed > 0 ? 27.143 * Math.exp(-0.175 * speed) - 0.44 : -1;
