import React from 'react';
import { Route, Routes, useNavigate, useRoutes } from 'react-router-dom';
import { routes } from './authorizedRoutes_v5';
import { VideoUploadPage } from 'pages/UploadPage/VideoUploadPage';
import { PracticePage } from 'pages/PracticePage';
import { RegisterPage } from 'pages/auth/Register/RegisterPage';
import { UserAccountPage } from 'pages/MyPage/UserAccountPage';
import { TrialUploadPage } from 'pages/TrialUploadPage';
import { MemberHomePage } from 'pages/MemberHome/index';
import { FiftyResultPage } from 'pages/FiftyResultPage';
import { MailAuthenticationPage } from 'pages/auth/MailAuthenticationPage';
import { ReLoginPage } from 'pages/auth/ReLoginPage';
import { ResetPasswordPage } from 'pages/auth/ResetPasswordPage';
import { ChangePasswordPage } from 'pages/auth/ChangePasswordPage';
import { ScoringPageForParttime } from 'pages/adminPage/parttimePages';
import { ResultSharePage } from 'pages/ResultPage/ResultSharePage';
import { LevelUpPage } from 'pages/LevelUpPage/LevelUpPage';
import { getAccountInfoAdapter } from 'adapters/getAccountInfoAdapter';
import { AnnouncePage } from 'pages/AnnouncePage';

import { AccountContext } from 'contexts/AccountContext';
import { AuthContext } from 'contexts/AuthContext';

import { AccountType, RunnerType } from 'adapters/repositories/typeDefinition';
import { BossCheckPage } from 'features/scoring/routes/BossCheckPage';
import { withFooter } from 'components/common/footerWrapper';

export const AuthorizedRouter = () => {
  const { setAccountInfo, setCurrentRunner, currentRunner } = React.useContext(AccountContext);
  const { setIsAuthenticated } = React.useContext(AuthContext);
  const router = useRoutes(routes);
  const navigate = useNavigate();

  // isAuthenticatedがTrueになった瞬間にアカウント情報を取りに行く処理を追加
  React.useEffect(() => {
    const curRunner = localStorage.getItem('currentRunner');
    getAccountInfoAdapter()
      .then((accountFromApi: AccountType) => {
        setAccountInfo(accountFromApi);
        // 続いて、localStorageを参照して適切なrunnerをセット
        if (!accountFromApi.runners) {
          return;
        }
        if (curRunner && curRunner.split('').length > 3) {
          // 要は保存されてたら、ということ
          const prevRunner = accountFromApi.runners.find(
            (runner: RunnerType) => runner.id === curRunner,
          );
          // prevRunnerがなかった場合、とりあえず0個目にする。
          console.log('prevRunner=', prevRunner);

          setCurrentRunner((!!prevRunner && prevRunner) || accountFromApi.runners[0]);
        } else {
          setCurrentRunner(accountFromApi.runners[0]);
        }
        return;
      })
      .catch((_) => {
        // アカウントはあるがランナーがない場合ここに入る
        setIsAuthenticated(false);
        localStorage.setItem('access', 'undefined');
      });
  }, []); // eslint-disable-line
  return (
    <>
      <Routes>
        <Route
          path="/"
          element={withFooter({
            children: <MemberHomePage />,
            currentRunner: currentRunner,
          })}
        />

        <Route
          path="/login"
          element={withFooter({
            children: <MemberHomePage />,
            currentRunner: currentRunner,
          })}
        />

        <Route
          path="/upload"
          element={withFooter({
            children: <VideoUploadPage />,
            currentRunner: currentRunner,
          })}
        />

        <Route
          path="/practice"
          element={withFooter({
            children: <PracticePage />,
            currentRunner: currentRunner,
          })}
        />
        <Route
          path="/trial-upload"
          element={withFooter({
            children: <TrialUploadPage />,
            currentRunner: currentRunner,
          })}
        />

        <Route
          path="/levelup"
          element={withFooter({
            children: <LevelUpPage />,
            currentRunner: currentRunner,
          })}
        />
        <Route path="/result-share/:videoId" element={<ResultSharePage />} />
        <Route
          path="/fifty_result/:videoId"
          element={withFooter({
            children: <FiftyResultPage />,
            currentRunner: currentRunner,
          })}
        />

        <Route
          path="/announce"
          element={withFooter({
            children: <AnnouncePage />,
            currentRunner: currentRunner,
          })}
        />
        <Route
          path="/mypage"
          element={withFooter({
            children: <UserAccountPage />,
            currentRunner: currentRunner,
          })}
        />
        <Route path="/boss-check" element={<BossCheckPage runner={currentRunner} />} />
        <Route path="/scoring" element={<ScoringPageForParttime />} />
        <Route path="/reset_pw" element={<ResetPasswordPage />} />
        <Route path="/change_pw" element={<ChangePasswordPage />} />
        <Route path="/register" element={<RegisterPage />} />
        <Route path="/varify/:token/:uid" element={<MailAuthenticationPage />} />
        <Route path="/relogin" element={<ReLoginPage />} />
      </Routes>
      <>{router}</>
    </>
  );
};
