import { nationType } from 'constants/nations';

const ckptForChina = [
  'つま先からかかとの順で接地',
  '股関節から動かす',
  '縦に動いているか',
  'うでふり',
] as const;

const ckptForIndia = [
  'かかとがお尻の下にくる',
  '地面についている方の脚',
  '足を下ろす',
  'うでふり',
] as const;

const ckptForQatar = [
  '遊脚の角度が90度か',
  'すねをまっすぐ下ろす',
  '接地時のしせい',
  'うでふり',
] as const;

/////////////////////
// ヨーロッパ
/////////////////////

const ckptForBritain = ['頭の位置', '腰の使い方', 'うでふり'] as const;

const ckptForGermany = ['スイングのタイミング', '接地後の足の動き', '遊脚のかたち'] as const;

const ckptForNorway = [
  '足のつき方',
  '地面をしっかり押す',
  '手と足のタイミング',
  '上に跳ぶ',
] as const;

/////////////////////
// アフリカ
/////////////////////

const ckptForMorocco = ['姿勢のキープ', '反発をもらう', '足首をかためる'] as const;

const ckptForSouthAfrica = ['姿勢のキープ', '上げた足を高くする', '遊脚のひざ'] as const;

const ckptForKenya = ['鼻とおへその向き', '弾めている', 'うでふり'] as const;

/////////////////////
// アメリカ
/////////////////////

const ckptForCanada = ['耳から足首がまっすぐ', '膝がそろっているか', '足のつき方'] as const;

const ckptForAmerica = [
  'しせいのキープ',
  'つま先の向き',
  '足を体の真下につく',
  '脚のきりかえ',
] as const;

const ckptForJamaica = [
  'しせいのキープ',
  '上げた脚のひざの角度',
  '地面をまっすぐ押す',
  'つま先の向き',
  '支持脚のひざ',
  '脚のきりかえ',
] as const;

/////////////////////
// オセアニア
/////////////////////

const ckptForAustralia = ['手足のリラックス', '手足の連動', '足のつき方'] as const;

const ckptForNewzealand = ['股関節から力を出す', '膝の高さ', '高くスキップする'] as const;

const ckptForPapua = ['頭の位置', '姿勢のキープ'] as const;

type ckptDictSelectableType = {
  [key in nationType]:
    | readonly typeof ckptForIndia[number][]
    | readonly typeof ckptForChina[number][]
    | readonly typeof ckptForQatar[number][]
    | readonly typeof ckptForBritain[number][]
    | readonly typeof ckptForGermany[number][]
    | readonly typeof ckptForNorway[number][]
    | readonly typeof ckptForSouthAfrica[number][]
    | readonly typeof ckptForMorocco[number][]
    | readonly typeof ckptForKenya[number][]
    | readonly typeof ckptForCanada[number][]
    | readonly typeof ckptForAmerica[number][]
    | readonly typeof ckptForJamaica[number][]
    | readonly typeof ckptForNorway[number][]
    | readonly typeof ckptForAustralia[number][]
    | readonly typeof ckptForNewzealand[number][]
    | readonly typeof ckptForPapua[number][];
};
export const checkpointDictForSelectable: ckptDictSelectableType = {
  インド: ckptForIndia,
  中国: ckptForChina,
  カタール: ckptForQatar,
  カナダ: ckptForCanada,
  アメリカ: ckptForAmerica,
  ジャマイカ: ckptForJamaica,
  ドイツ: ckptForGermany,
  ノルウェー: ckptForNorway,
  イギリス: ckptForBritain,
  モロッコ: ckptForMorocco,
  南アフリカ: ckptForSouthAfrica,
  ケニア: ckptForKenya,
  パプアニューギニア: ckptForPapua,
  ニュージーランド: ckptForNewzealand,
  オーストラリア: ckptForAustralia,
  東京: ckptForChina,
  札幌: ckptForChina,
  京都: ckptForChina,
};

type allCkptsType = ckpt_india_type | ckpt_china_type | ckpt_Britain_type;
export type scoreTypeForSelectable = {
  [key in allCkptsType]: string;
};
