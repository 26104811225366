import { Grid } from '@mui/material';
import { islandExamType } from 'features/selectable_training/types';
import React, { FC, useState } from 'react';
import classes from './examscreen.module.css';
import useSingleMenuWatchModal from 'hooks/useSingleMenuWatchModal';

type Props = {
  menus: islandExamType[];
};
export const ExamMenus: FC<Props> = ({ menus }) => {
  const [exam, setExam] = useState<islandExamType | null>(null);
  const { forceModalOpen, MenuWatchModal } = useSingleMenuWatchModal(exam);

  const handleMenuTap = (examTapped: islandExamType) => () => {
    setExam(examTapped);
    forceModalOpen(true);
  };

  return (
    <div style={{ marginTop: '30vw', width: '96vw', marginLeft: '1.2vw' }}>
      {MenuWatchModal}
      <div>
        {menus.map((m) => (
          <>
            <div className={classes.menuCard}>
              <Grid container>
                <Grid item xs={4}>
                  <div>
                    <img
                      className={classes.thumbnail}
                      src={m.menu_thumbnail}
                      alt="メニュー"
                      width="100%"
                      onClick={handleMenuTap(m)}
                    />
                  </div>
                </Grid>
                <Grid item xs={8}>
                  <div>
                    <div>
                      <img className={classes.flagImg} src={m.flag} alt="国旗" />
                    </div>
                    <div className={classes.menuText}>{m.title}</div>
                  </div>
                </Grid>
              </Grid>
            </div>
          </>
        ))}
      </div>
    </div>
  );
};
