import { AccountInfoType } from 'features/auth/types';

export type ValidationMsgType = {
  userName: string;
  fullName: string;
  email: string;
  password: string;
  confirmPassword: string;
  height: string;
  gender: string;
};

export const checkIsFormComplete = (
  accountInfo: AccountInfoType,
  validationMessage: ValidationMsgType,
) => {
  const isAllFilled = Object.values(accountInfo).every((f) => String(f).length > 0 || f === 'con');
  console.log(accountInfo);
  const isNoValidation = Object.values(validationMessage).every((f) => f === '');
  console.log(isAllFilled, isNoValidation);
  if (isAllFilled && isNoValidation) {
    return true;
  }
  return false;
};

export const crtValidateMessage = (
  key: string,
  input: string,
  accountInfo: AccountInfoType,
  validationMessage: ValidationMsgType,
) => {
  if (key === 'userName' && input.length > 10) {
    return {
      ...validationMessage,
      [key]: '下の名前が10文字を超える場合はニックネームか漢字をご利用ください',
    };
  }

  if (key === 'email') {
    const reg = /^[A-Za-z0-9]{1}[A-Za-z0-9_.-]*@{1}[A-Za-z0-9_.-]{1,}.[A-Za-z0-9]{1,}$/;
    if (!reg.test(input)) {
      return {
        ...validationMessage,
        [key]: 'emailアドレスが不適切です',
      };
    }
  }

  if (key === 'password') {
    if (input.length <= 7) {
      return {
        ...validationMessage,
        password: 'パスワードは8文字以上にしてください',
      };
    }
  }

  return {
    userName: '',
    email: '',
    password: '',
    confirmPassword: '',
    gender: '',
    height: '',
  };
};
