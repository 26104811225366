import React, { memo, useEffect, FC } from 'react';
import classes from './progressbar.module.css';

type Props = {
  percent: number;
  strokeColor: string;
  bgColor: string;
};

export const MyCircularProgress: FC<Props> = memo(
  ({ percent, strokeColor, bgColor }) => {
    const [progress, setProgress] = React.useState(0);
    useEffect(() => {
      const interval = setInterval(() => {
        setProgress((prevProgress) => {
          const nextProgress = prevProgress + percent / 100;
          return nextProgress >= percent ? percent : nextProgress;
        });
      }, 5);

      return () => {
        clearInterval(interval);
      };
    }, [percent]);

    const radius = window.innerWidth * 0.14;
    const circumference = 2 * Math.PI * radius;
    const offset = circumference - (progress / 100) * circumference;
    return (
      <div className={classes.circularProgress}>
        <svg
          className={classes.circularProgress}
          width={radius * 2.2}
          height={radius * 2.2}
        >
          <g transform={`rotate(-90 ${radius} ${radius})`}>
            <circle
              className={classes.progressBackground}
              cx={radius * 0.9}
              cy={radius * 1.1}
              r={radius - 2}
              style={{ stroke: bgColor }}
            />
            <circle
              className={classes.progressBar}
              cx={radius * 0.9}
              cy={radius * 1.1}
              r={radius - 2}
              strokeDasharray={circumference}
              strokeDashoffset={offset}
              style={{ stroke: strokeColor }}
            />
          </g>
        </svg>
      </div>
    );
  },
);
