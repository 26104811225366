import { islandExamType } from 'features/selectable_training/types';
import canada_icon from '../static/canada_icon.png';
import america_icon from '../static/america_icon.png';
import jamaica_icon from '../static/jamaica_icon.png';
import china_icon from '../static/china_icon.png';
import india_icon from '../static/india_icon.png';
import qatar_icon from '../static/qatar_icon.png';
import papua_icon from '../static/papua_icon.png';
import australia_icon from '../static/australia_icon.png';
import nzl_icon from '../static/nzl_icon.png';
import germany_icon from '../static/germany_icon.png';
import britain_icon from '../static/britain_icon.png';
import norway_icon from '../static/norway_icon.png';
import south_africa_icon from '../static/south_africa_icon.png';
import morocco_icon from '../static/morocco_icon.png';
import kenya_icon from '../static/kenya_icon.png';
import tokyo_icon from '../static/tokyo_icon.png';

// 国旗のインポート
import china_flag from '../static/china_flag.png';
import india_flag from '../static/india_flag.png';
import qatar_flag from '../static/qatar_flag.png';
import papua_flag from '../static/papua_flag.png';
import australia_flag from '../static/australia_flag.png';
import nzl_flag from '../static/nzl_flag.png';
import germany_flag from '../static/germany_flag.png';
import britain_flag from '../static/britain_flag.png';
import norway_flag from '../static/norway_flag.png';
import south_africa_flag from '../static/south_africa_flag.png';
import morocco_flag from '../static/morocco_flag.png';
import kenya_flag from '../static/kenya_flag.png';
import canada_flag from '../static/canada_flag.png';
import america_flag from '../static/america_flag.png';
import jamaica_flag from '../static/jamaica_flag.png';
import japan_flag from '../static/blank_flag.png';
import { islandType } from 'features/selectable_course/types';

///// メニューのサムネ画像のインポート
import posture_trac from 'components/MenuConst/thumbnails/posture_trac.png';
import armSwingThumb from 'components/MenuConst/thumbnails/arm_swing_intro.png';
import startThumb from 'components/MenuConst/thumbnails/start_thumb.png';
// アメリカ
import backKickThumb from '../static/backKick.png';
import straightLegThumb from '../static/straightLeg.png';
import twoStepHighKneeThumb from '../static/twoStepHighKnee.png';
// アジア
import torottingThumb from '../static/torotting.png';
import hignKneeFourThumb from '../static/hignKneeFour.png';
import hignKneeSevenThumb from '../static/highKneeSeven.png';
// ヨーロッパ
import kenkenThumb from '../static/kenken.png';
import boundingThumb from '../static/bounding.png';
import lungeThumb from '../static/lungeRun.png';
// オセアニア
import fastSkipThumb from '../static/fastSkip.png';
import slowSkipThumb from '../static/slowSkip.png';
import highSkipThumb from '../static/highSkip.png';
// アフリカ
import ankleHopThumb from '../static/ankleHop.png';
import singleAnkleHopThumb from '../static/singleAnkleHopThumb.png';
import twoSkipThumb from '../static/twoSkipThumb.png';

// styleをインポート
import classes from './menutext.module.css';
import React from 'react';

/////////////////////////////
///// プッシュ系（Asia） ///////
/////////////////////////////

const trotting: islandExamType = {
  title: 'トロッティング',
  videoLink: 'https://www.youtube.com/embed/Lf9_WJMtzkc',
  country: '中国',
  thumbnail: china_icon,
  flag: china_flag,
  menu_thumbnail: torottingThumb,
};

const highKneeFour: islandExamType = {
  title: 'ハイニー（4の字）',
  videoLink: 'https://www.youtube.com/embed/A1M7DicbmQA',
  country: 'インド',
  thumbnail: india_icon,
  flag: india_flag,
  menu_thumbnail: hignKneeFourThumb,
};

const highKneeSeven: islandExamType = {
  title: 'ハイニー（7の字）',
  videoLink: 'https://www.youtube.com/embed/fw9P6bl1zNo',
  country: 'カタール',
  thumbnail: qatar_icon,
  flag: qatar_flag,
  menu_thumbnail: hignKneeSevenThumb,
};

export const pushMenus = [trotting, highKneeFour, highKneeSeven];

//////////////////////
///// シザース系 ////
//////////////////////

const backKick: islandExamType = {
  title: 'バックキック',
  videoLink: 'https://www.youtube.com/embed/6S7qBY1T2Qo',
  country: 'カナダ',
  thumbnail: canada_icon,
  flag: canada_flag,
  menu_thumbnail: backKickThumb,
};

const straightLeg: islandExamType = {
  title: 'ストレートレッグ',
  videoLink: 'https://www.youtube.com/embed/lWMr1Cvcz14',
  country: 'アメリカ',
  thumbnail: america_icon,
  flag: america_flag,
  menu_thumbnail: straightLegThumb,
};

const twoStepHighKnee: islandExamType = {
  title: '2ステップハイニー',
  videoLink: 'https://www.youtube.com/embed/2W5K1FosyiE',
  country: 'ジャマイカ',
  thumbnail: jamaica_icon,
  flag: jamaica_flag,
  menu_thumbnail: twoStepHighKneeThumb,
};

export const scissorsMenus = [backKick, straightLeg, twoStepHighKnee];

/////////////////////////////
///// コンビネーション系（Oceania） ///////
/////////////////////////////

const fastSkip: islandExamType = {
  title: '速いスキップ',
  videoLink: 'https://www.youtube.com/embed/oYAs3vTQqsw',
  country: 'パプアニューギニア',
  thumbnail: papua_icon,
  flag: papua_flag,
  menu_thumbnail: fastSkipThumb,
};

const highSkip: islandExamType = {
  title: '高いスキップ',
  videoLink: 'https://www.youtube.com/embed/I8pqiC2UJ5k',
  country: 'ニュージーランド',
  thumbnail: nzl_icon,
  flag: nzl_flag,
  menu_thumbnail: highSkipThumb,
};

const slowSkip: islandExamType = {
  title: 'ゆったりスキップ',
  videoLink: 'https://www.youtube.com/embed/KVFWx9-RGSA',
  country: 'オーストラリア',
  thumbnail: australia_icon,
  flag: australia_flag,
  menu_thumbnail: slowSkipThumb,
};

export const combinationMenus = [fastSkip, highSkip, slowSkip];

//////////////////////
///// スイング系（ヨーロッパ） ////
//////////////////////

const twoHop: islandExamType = {
  title: '2歩ずつケンケン',
  videoLink: 'https://www.youtube.com/embed/OHFQrZjzI1M',
  country: 'ドイツ',
  thumbnail: germany_icon,
  flag: germany_flag,
  menu_thumbnail: kenkenThumb,
};

const bounding: islandExamType = {
  title: 'バウンディング',
  videoLink: 'https://www.youtube.com/embed/rOEQfsDamUI',
  country: 'ノルウェー',
  thumbnail: norway_icon,
  flag: norway_flag,
  menu_thumbnail: boundingThumb,
};

const lungeRun: islandExamType = {
  title: 'ランジラン',
  videoLink: 'https://www.youtube.com/embed/zJymXeAPyuU',
  country: 'イギリス',
  thumbnail: britain_icon,
  flag: britain_flag,
  menu_thumbnail: lungeThumb,
};

export const swingMenus = [twoHop, bounding, lungeRun];

//////////////////////
///// スティフネス系（アフリカ） ////
//////////////////////

const ankleHop: islandExamType = {
  title: 'アンクルホップ',
  videoLink: 'https://www.youtube.com/embed/Vr5bO6ZV2OM',
  country: 'モロッコ',
  thumbnail: morocco_icon,
  menu_thumbnail: ankleHopThumb,
  flag: morocco_flag,
  explanation: (
    <div className={classes.menuText}>
      <div className={classes.points}>
        ・足首を固めよう！ <br />
        ・良い姿勢で弾もう！
      </div>
    </div>
  ),
};

const singleAnkleHop: islandExamType = {
  title: 'シングルアンクルホップ',
  videoLink: 'https://www.youtube.com/embed/LVdMxv5P3pY',
  country: '南アフリカ',
  thumbnail: south_africa_icon,
  menu_thumbnail: singleAnkleHopThumb,
  flag: south_africa_flag,
  explanation: <div className={classes.menuText}>・動画の真似をしてみてね！</div>,
};

const twoSkip: islandExamType = {
  title: '2スキップ',
  videoLink: 'https://www.youtube.com/embed/9OjeEsLaGd8',
  country: 'ケニア',
  thumbnail: kenya_icon,
  menu_thumbnail: twoSkipThumb,
  flag: kenya_flag,
};

export const stiffnessMenus = [ankleHop, singleAnkleHop, twoSkip];

const goodPosture: islandExamType = {
  title: '良い姿勢の作り方',
  videoLink: 'https://www.youtube.com/embed/FOwDt73CZ9M',
  country: '東京',
  thumbnail: tokyo_icon,
  menu_thumbnail: posture_trac,
  flag: japan_flag,
};

const armSwing: islandExamType = {
  title: '腕振りの基本',
  videoLink: 'https://www.youtube.com/embed/C86NSrb8iHw',
  country: '東京',
  thumbnail: tokyo_icon,
  menu_thumbnail: armSwingThumb,
  flag: japan_flag,
};

const startBasic: islandExamType = {
  title: 'スタートの基礎',
  videoLink: 'https://www.youtube.com/embed/WvZgkDJewGE',
  country: '東京',
  thumbnail: tokyo_icon,
  menu_thumbnail: startThumb,
  flag: japan_flag,
  explanation: (
    <div className={classes.menuText}>
      この動画は初級編「9級」からの抜粋です。スタートを見てもらいたい場合は初級編9級の昇級テストを受けよう！
    </div>
  ),
};

export const baseMenus = [goodPosture, armSwing, startBasic];

type continentMenuDictType = {
  [key in islandType]: islandExamType[];
};

export const continentMenuDict: continentMenuDictType = {
  asia: pushMenus,
  america: scissorsMenus,
  oceania: combinationMenus,
  europe: swingMenus,
  africa: stiffnessMenus,
  start: baseMenus,
};
