import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { LevelUpPage } from 'pages/LevelUpPage/LevelUpPage';
import { VideoUploadPage } from 'pages/UploadPage/VideoUploadPage';

export const VideoUploadRoutes = () => {
  return (
    <Routes>
      <Route path="levelup" element={<LevelUpPage />} />
      <Route path="practice" element={<VideoUploadPage />} />
    </Routes>
  );
};
