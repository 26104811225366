import React from 'react';
import classes from 'components/MenuConst/menutextDecoration.module.css';
import { menuType } from 'pages/PracticePage/type';
import splitJumpthumb from '../../../static/splitjump_thumb.png';
import sevenWalkThumb from '../../../static/sevenWalkThumb.png';
import fourWalkThumb from '../../../static/fourWalkThumb.png';
import swingJumpThumb from '../../../static/swingJumpThumb.png';
import chairJumpThumb from '../../../static/chairJumpThumb.png';
import toeSideWalkThumb from '../../../static/toeSideWalkThumb.png';
import goopahThumb from '../../../static/gooPahJumpThumb.png';
import pahChokiThumb from '../../../static/pahChokiThumb.png';
import gooChokiThumb from '../../../static/gooChokiThumb.png';

export const SplitJump: menuType = {
  duration: 5, // 所要時間（分）を入れる
  title: 'スプリットジャンプ',
  isInside: 'able',
  target: ['足の動き'],
  menuVariation: ['tech'],
  grade: 2,
  image: splitJumpthumb,
  video: [
    {
      link: 'https://www.youtube.com/embed/4u1K8gA4gHs',
      title: 'スプリットジャンプ',
      message: (
        <div>
          <div className={classes.subtitle}>スプリットジャンプ</div>
          <div className={classes.content}>☆ぜひ動画のまねをしてやってみてね！</div>
        </div>
      ),
    },
  ],
};

export const SwingJump: menuType = {
  duration: 5, // 所要時間（分）を入れる
  title: 'スイングジャンプ',
  isInside: 'able',
  target: ['足の動き'],
  menuVariation: ['tech'],
  grade: 2,
  image: swingJumpThumb,
  video: [
    {
      link: 'https://www.youtube.com/embed/VyjJbaNXdoQ',
      title: 'スイングジャンプ',
      message: (
        <div>
          <div className={classes.subtitle}>スイングジャンプ</div>
          <div className={classes.content}>☆ぜひ動画のまねをしてやってみてね！</div>
        </div>
      ),
    },
  ],
};

export const ChairJump: menuType = {
  duration: 5, // 所要時間（分）を入れる
  title: 'イスジャンプ',
  isInside: 'able',
  target: ['足の動き'],
  menuVariation: ['tech'],
  grade: 2,
  image: chairJumpThumb,
  video: [
    {
      link: 'https://www.youtube.com/embed/R5oktGH5H3s',
      title: 'イスジャンプ',
      message: (
        <div>
          <div className={classes.subtitle}>イスジャンプ</div>
          <div className={classes.content}>☆ぜひ動画のまねをしてやってみてね！</div>
        </div>
      ),
    },
  ],
};

export const ToeSideWalk: menuType = {
  duration: 5, // 所要時間（分）を入れる
  title: 'つまさきサイド歩き',
  isInside: 'able',
  target: ['足の動き'],
  menuVariation: ['tech'],
  grade: 2,
  image: toeSideWalkThumb,
  video: [
    {
      link: 'https://www.youtube.com/embed/wovDSTGHE5E',
      title: 'つまさきサイド歩き',
      message: (
        <div>
          <div className={classes.subtitle}>つまさきサイド歩き</div>
          <div className={classes.content}>☆ぜひ動画のまねをしてやってみてね！</div>
        </div>
      ),
    },
  ],
};

export const GooPahJump: menuType = {
  duration: 5, // 所要時間（分）を入れる
  title: 'グーパージャンプ',
  isInside: 'able',
  target: ['足の動き'],
  menuVariation: ['tech'],
  grade: 2,
  image: goopahThumb,
  video: [
    {
      link: 'https://www.youtube.com/embed/Xz5KIXYXmHw',
      title: 'グーパージャンプ',
      message: (
        <div>
          <div className={classes.subtitle}>グーパージャンプ</div>
          <div className={classes.content}>☆ぜひ動画のまねをしてやってみてね！</div>
        </div>
      ),
    },
  ],
};

export const PahChokiJump: menuType = {
  duration: 5, // 所要時間（分）を入れる
  title: 'パーチョキジャンプ',
  isInside: 'able',
  target: ['足の動き'],
  menuVariation: ['tech'],
  grade: 2,
  image: pahChokiThumb,
  video: [
    {
      link: 'https://www.youtube.com/embed/4oQJRsy2IAQ',
      title: 'パーチョキジャンプ',
      message: (
        <div>
          <div className={classes.subtitle}>パーチョキジャンプ</div>
          <div className={classes.content}>☆ぜひ動画のまねをしてやってみてね！</div>
        </div>
      ),
    },
  ],
};

export const GooChokiJump: menuType = {
  duration: 5, // 所要時間（分）を入れる
  title: 'グーチョキジャンプ',
  isInside: 'able',
  target: ['足の動き'],
  menuVariation: ['tech'],
  grade: 2,
  image: gooChokiThumb,
  video: [
    {
      link: 'https://www.youtube.com/embed/4oQJRsy2IAQ',
      title: 'グーチョキジャンプ',
      message: (
        <div>
          <div className={classes.subtitle}>グーチョキジャンプ</div>
          <div className={classes.content}>☆ぜひ動画のまねをしてやってみてね！</div>
        </div>
      ),
    },
  ],
};

export const FourWalk: menuType = {
  duration: 5, // 所要時間（分）を入れる
  title: '4の字ウォーク',
  isInside: 'able',
  target: ['足の動き'],
  menuVariation: ['tech'],
  grade: 2,
  image: fourWalkThumb,
  video: [
    {
      link: 'https://www.youtube.com/embed/T2bDZ0CWcRk',
      title: '4の字ウォーク',
      message: (
        <div>
          <div className={classes.subtitle}>4の字ウォーク</div>
          <div className={classes.content}>☆ぜひ動画のまねをしてやってみてね！</div>
        </div>
      ),
    },
  ],
};

export const SevenWalk: menuType = {
  duration: 5, // 所要時間（分）を入れる
  title: '7の字ウォーク',
  isInside: 'able',
  target: ['足の動き'],
  menuVariation: ['tech'],
  grade: 2,
  image: sevenWalkThumb,
  video: [
    {
      link: 'https://www.youtube.com/embed/vqf3ulfR4JA',
      title: '7の字ウォーク',
      message: (
        <div>
          <div className={classes.subtitle}>7の字ウォーク</div>
          <div className={classes.content}>☆ぜひ動画のまねをしてやってみてね！</div>
        </div>
      ),
    },
  ],
};
