import america_achievement from '../static/america_achievement.png';
import africa_achievement from '../static/africa_achievement.png';
import europe_achievement from '../static/europe_achievement.png';
import asia_achievement from '../static/asia_achievement.png';
import oceania_achievement from '../static/oceania_achievement.png';
import { islandType } from 'features/selectable_course/types';

export const decideContinent = (continent: islandType) => {
  switch (continent) {
    case 'start':
      return asia_achievement;
    case 'asia':
      return asia_achievement;
    case 'america':
      return america_achievement;
    case 'europe':
      return europe_achievement;
    case 'oceania':
      return oceania_achievement;
    case 'africa':
      return africa_achievement;
    default:
      throw new Error('Invalid input');
  }
};
