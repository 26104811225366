import React, { FC, memo } from 'react';
import classes from '../onboardingCarousel.module.css';
import result from 'features/selectable_course/static/resultSample.png';

const ResultCut: FC = memo(() => {
  return (
    <>
      <div className={classes.blackBorder} style={{ fontSize: '1rem', marginBottom: '6vw' }}>
        動画は決まった曜日にコーチが添削！
        <br />
        結果とアドバイスを確認しよう！
      </div>
      <div className={classes.iidukaFirstWrapper} style={{ width: '82vw' }}>
        <img src={result} alt="" width="80%"></img>
      </div>
    </>
  );
});

export default ResultCut;
