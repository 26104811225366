import React from 'react';
import { AccountContext } from 'contexts/AccountContext';
import { PageTitle } from '../../components/common/PageTitle';
import { StickerModal } from '../../components/Sticker/StickerModal';
import { UploadStepViewer } from './components/UploadStepViewer';
import { goToTags } from './func';
import { TextInput } from './components/TextInput';
import { UploadNotification } from 'components/Notifications';
import '../../index.css';
import { convertRunnerGrade } from 'components/constants';
import { useVideoUploadComponent } from './hooks/useVideoUpload';
import VideoUploadButtonByS3 from 'features/upload/components/atoms/UploadButtonWithProgress';

export type alertStatusType = {
  open: boolean;
  status: 'OK' | 'ERROR' | 'default';
};

type inputEvent = React.ChangeEvent<HTMLInputElement>;
export type handleInputChange = (e: inputEvent) => void;

export const VideoUploadPage = () => {
  const { accountInfo, currentRunner } = React.useContext(AccountContext);
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [activeStep, setActiveStep] = React.useState(0);
  const [formContent, setFormContent] = React.useState('');
  const [alertStatus, setAlertStatus] = React.useState<alertStatusType>({
    open: false,
    status: 'default',
  });

  const onChooseVideo = () => {
    setActiveStep(1);
    goToTags();
  };

  const { file, uploadButton } = useVideoUploadComponent({
    onChooseVideo: onChooseVideo,
  });

  if (!currentRunner || !accountInfo) {
    throw new Error('アカウントがありません');
  }
  const handleFormChange: handleInputChange = (e) => {
    setFormContent(e.target.value);
    if (e.target.value.length < 1 && activeStep === 2) {
      setActiveStep(1);
      return;
    }
    if (!file) {
      setActiveStep(0);
      return;
    }
    setActiveStep(2);
  };

  const params = new FormData();
  if (!!file && activeStep >= 2) {
    params.append('content', file);
    params.append('fileName', file.name);
    params.append('uploaded_by', accountInfo.id);
    params.append('upload_runner', currentRunner.id);
    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'boolean' is not assignable to pa... Remove this comment to see the full error message
    params.append('is_exam', false);
    params.append('text', formContent);
    params.append('menuText', 'デフォルト');
  }

  const sendCompleteFuncs = () => {
    setActiveStep(0);
    setAlertStatus({ open: true, status: 'OK' });
    setIsModalOpen(true);
    // setIsModalOpen(true);
  };

  const sendFailFuncs = () => {
    console.log('upload失敗');
    setAlertStatus({ open: true, status: 'ERROR' });
  };

  const reibun = '（例）気持ちよく走れたが、足を上げられているか自信がない。';

  return (
    <div>
      <PageTitle title={'練習を記録する'} />
      <UploadNotification alertStatus={alertStatus} setAlertStatus={setAlertStatus} />

      <div
        style={{
          textAlign: 'center',
          padding: '5px',
        }}
      >
        <UploadStepViewer activeStep={activeStep} />

        <StickerModal
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          grade={(!!currentRunner && convertRunnerGrade(currentRunner)) || '20'}
        />
        {uploadButton}

        <div
          style={{
            textAlign: 'center',
            padding: '10px',
            marginTop: '30px',
          }}
          id="tags"
        >
          <>
            <TextInput
              title="✏️練習の感想を書いてみよう"
              reibun={reibun}
              value={formContent}
              onChange={handleFormChange}
            />
          </>
        </div>

        <VideoUploadButtonByS3
          activeStep={activeStep}
          params={params}
          completeFunc={sendCompleteFuncs}
          failFunc={sendFailFuncs}
          formContent={formContent}
          color="primary"
        />

        <div style={{ marginTop: '100px' }}>
          <br />
        </div>
      </div>
    </div>
  );
};
