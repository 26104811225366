import {
  ScoreEditType,
  //eslint-diable-next-line
} from 'components/MenuConst/GradeGoalConst';
import { VideoType } from 'adapters/repositories/typeDefinition';

import { convertGradeByRunner } from 'components/constants';

import { autoCommentForTrac10Pkg } from './crtCommentTrac_10';
import { autoCommentForSelectablePkg } from './selectable';
import { returnDictType } from 'features/scoring/constants/spsheetURL';

export const autoComment = (
  chosenVideo: VideoType,
  score: ScoreEditType,
  student_group = '',
  checkPointDict: returnDictType,
) => {
  const runnerInfo = chosenVideo.upload_runner;

  let comment = '';

  if (chosenVideo.menu) {
    comment = autoCommentForSelectablePkg(chosenVideo, score, checkPointDict);
    console.log(comment);
    return comment;
  }

  const kyuOfVideo = convertGradeByRunner(runnerInfo, chosenVideo.grade_before);
  comment = autoCommentForTrac10Pkg(chosenVideo, score, kyuOfVideo);
  return comment;
};

function crt_line4(video_memo: string | undefined) {
  if (!video_memo) {
    return '来週からはよかったら質問してね！！';
  }

  return `コメントの「${video_memo}」という点ですが、`;
}
