import React, { useEffect, useState, useMemo } from 'react';
import { TextField, InputAdornment, IconButton } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import { MenuCard } from './MenuCard';
import { menuType } from '../type';
import { usePlaylistWatchModal } from 'pages/PracticePage/hooks/usePlaylistWatchModal';
import { RunnerType } from 'adapters/repositories/typeDefinition';
import { decideMenuForTrac } from 'components/MenuConst/DecideMenu/decideMenuForTrac';
import { grades10 } from 'components/MenuConst/GradeGoalConst';

type Props = {
  runner: RunnerType;
};

type handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => void;

export const AllMenu = ({ runner }: Props) => {
  const [searchWord, setSearchWord] = React.useState('');
  const [selectedMenu, setSelectedMenu] = useState<menuType | undefined>(undefined);

  const allMenu = useMemo(() => {
    let menuList: menuType[] = [];
    console.log('メニューを計算');

    if (['normal', 'newtrac', 'TRAC'].includes(runner.student_group || 'normal')) {
      for (let g = 0; g <= runner.grade; g++) {
        const grade_ = String(10 - g) as grades10;
        const menu = decideMenuForTrac(grade_);
        menuList = menuList.concat(menu);
      }
    }
    return menuList.filter(
      (value, index, mList) => index === mList.findIndex((m) => m.title === value.title),
    );
  }, [runner]);

  useEffect(() => {
    if (searchWord === '') {
      return;
    }
    handleSearch();
  }, [searchWord]);

  const [menusToShow, setMenusToShow] = React.useState<menuType[]>(allMenu);
  const handleChange: handleInputChange = (e) => {
    setSearchWord(e.target.value);
  };
  const handleSearch = () => {
    console.log('確認');
    let _videos = allMenu.filter((m) => {
      return (
        m.title.indexOf(searchWord) !== -1 ||
        (!!m.hitWordList &&
          m.hitWordList.some(
            (word) => searchWord.indexOf(word) !== -1 || word.indexOf(searchWord) !== -1,
          ))
      );
    });
    setMenusToShow(_videos);
  };

  const { forceModalOpen, MenuWatchModal } = usePlaylistWatchModal(selectedMenu);

  const handleMenuClick = (m: menuType) => () => {
    forceModalOpen(true);
    setSelectedMenu(m);
  };

  return (
    <>
      {MenuWatchModal}
      <div style={{ paddingTop: '16px', paddingBottom: '10px' }}>
        <TextField
          variant="outlined"
          label="練習メニューをさがす"
          style={{
            width: '95%',
            marginLeft: '10px',
          }}
          multiline
          value={searchWord}
          onChange={handleChange}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton style={{ padding: '4px' }} onClick={handleSearch}>
                  {/* @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call. */}
                  <SearchIcon color="primary" size="small" />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </div>
      {menusToShow.length < 1 ? (
        <div style={{ margin: '12px' }}>検索結果がありません</div>
      ) : (
        <div>
          {menusToShow.map((m, idx) => {
            return <MenuCard menuPlaylist={m} handleMenuClick={handleMenuClick(m)} key={idx} />;
          })}
        </div>
      )}
    </>
  );
};
