import React, { FC, memo } from 'react';
import classes from '../onboardingCarousel.module.css';
import iiduka from 'features/selectable_course/static/onishi.png';

const FinalCut: FC = memo(() => {
  return (
    <>
      <div className={classes.blackBorder} style={{ fontSize: '0.96rem', marginBottom: '15vw' }}>
        早速やってみよう！
      </div>
      <div className={classes.iidukaFirstWrapper} style={{ width: '60vw' }}>
        <img src={iiduka} alt="" width="100%"></img>
      </div>
    </>
  );
});

export default FinalCut;
