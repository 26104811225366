import { menuType } from 'pages/PracticePage/type';
import { grades10 } from '../GradeGoalConst';
import { PostureIntro, Senobi, Sonkyo } from '../menus/grade_0/10kyu_trac';
import { StartPosture } from '../menus/grade_1/9kyu_trac';
import { JumpAndRun, SenobiJump, SupportJump, ToeJump } from '../menus/grade_2/8kyu_trac';
import { ArmSwingTest, SwingIntro, Swing_A, Swing_B, Swing_C } from '../menus/grade_3/7kyu_trac';
import { LungeSquat, LungeWalk, SuriSuriWalk } from '../menus/grade_4/6kyu_trac';
import { KabeTest, KabeWalk, MountainClimber, MountainWalker } from '../menus/grade_5/5kyu_trac';
import { KabeRenzoku, ThreeSecDashTest } from '../menus/grade_6/4kyu_trac';
import { JigZagJump, OneLegJump, SideHop, SideHopArmRaise } from '../menus/grade_7/3kyu_trac';
import { HighKnee, MarchWalk } from '../menus/grade_8/2kyu_trac';
import { HighKnee1kyu, SixSecDash } from '../menus/grade_9/1kyu_trac';

export const decideMenuForTrac = (grade: grades10): menuType[] => {
  if (grade === '10') {
    return [PostureIntro, Senobi, Sonkyo];
  }
  if (grade === '9') {
    return [StartPosture];
  }
  if (grade === '8') {
    return [SupportJump, ToeJump, SenobiJump, JumpAndRun];
  }
  if (grade === '7') {
    return [SwingIntro, Swing_A, Swing_B, Swing_C, ArmSwingTest];
  }
  if (grade === '6') {
    return [SuriSuriWalk, LungeSquat, LungeWalk];
  }
  if (grade === '5') {
    return [MountainWalker, MountainClimber, KabeWalk, KabeTest];
  }
  if (grade === '4') {
    return [KabeRenzoku, LungeSquat, SuriSuriWalk, ThreeSecDashTest];
  }
  if (grade === '3') {
    return [OneLegJump, JigZagJump, SideHop, SideHopArmRaise];
  }
  if (grade === '2') {
    return [MarchWalk, HighKnee];
  }
  if (grade === '1') {
    return [HighKnee1kyu, SixSecDash];
  }
  return [PostureIntro];
};
