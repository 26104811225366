import React, { FC, useEffect, useState } from 'react';
import { GradeSheet } from './components/GradeSheet';
import { PageTitle } from '../../components/common/PageTitle';
import { useFirstOnboardingModal } from 'hooks/FirstOnboarding/useFirstOnboardingModal';
import { useGetDaus } from 'hooks/useGetDaus';
import loadingGif from 'static/running-101-gif.gif';
import { useFetchAccount } from './hooks/useFetchAccount';
import { GuidanceTools } from './components/GuidanceTools';
import { onboardType } from './components/GuidanceTools/type';
import { decideOnboardState } from './func/decideOnboardState';
import { AnnounceBar } from './components/AnnounceBar';
import { putRunnerInfo } from 'v1apiRequest';
import { useNavigate } from 'react-router-dom';
import { usePaymentModal } from 'hooks/Payment/usePaymentModal';

// let count = 0;

export const MemberHomePage: FC = () => {
  const [onboardState, setOnboardState] = useState<onboardType>('beforeMsg');
  const { currentRunner } = useFetchAccount();
  useGetDaus((!!currentRunner && currentRunner.id) || '');
  const { forceModalOpen, OnboardModal } = useFirstOnboardingModal(currentRunner);
  const { forcePaymentModalOpen, PaymentModal } = usePaymentModal(currentRunner);
  const navigate = useNavigate();

  // grade=0の場合にオンボーディングが必要ならモーダル表示
  useEffect(() => {
    if (!currentRunner || currentRunner.grade > 2) {
      forcePaymentModalOpen(false);

      return;
    }
    if (currentRunner.grade === 2) {
      console.log(currentRunner);
      forcePaymentModalOpen(true);
      return;
    }
    forcePaymentModalOpen(false);
    const shouldOnboard =
      currentRunner.grade === 0 && !localStorage.getItem(String(currentRunner.id));
    console.log('shouldOnboard=', shouldOnboard);
    console.log('cr=', currentRunner);
    console.log('sh', localStorage.getItem(String(currentRunner.id)));
    if (shouldOnboard) {
      forceModalOpen(true);
    }
  }, [currentRunner]);

  // レンダリングと同時にonboardの状態を取る
  useEffect(() => {
    if (!currentRunner) {
      return;
    }
    const onboardPhase = decideOnboardState(currentRunner);
    console.log(onboardPhase);
    setOnboardState(onboardPhase);
  }, [currentRunner]);

  // 本当はuseCallbackしたいがなんか所望の挙動にならない
  const onCloseTreasureBox = () => {
    if (onboardState === 'beforeMsg') {
      console.log(onboardState);
      if (!!currentRunner && currentRunner.grade === 0) {
        setOnboardState('beforePractice');
        putRunnerInfo({ onboarding: 'beforePractice', id: currentRunner.id });
        navigate('/');
      }
      if (!!currentRunner && currentRunner.grade > 0) {
        setOnboardState('beforeChallenge');
        putRunnerInfo({ onboarding: 'beforeChallenge', id: currentRunner.id });
      }
    }
    return;
  };

  if (!currentRunner) {
    return (
      <>
        <PageTitle title="トップページ" />
        <img alt="" src={loadingGif} height="140" width="auto" style={{ marginTop: '30px' }} />
      </>
    );
  }

  return (
    <div style={{ textAlign: 'center' }}>
      {OnboardModal}
      {PaymentModal}
      <AnnounceBar onboardPhase={onboardState} runner={currentRunner} />

      <div
        style={{
          textAlign: 'center',
          padding: '5px',
          margin: '16px 0px 8px 0px',
        }}
      >
        <GradeSheet runner={currentRunner} onboardState={onboardState} />

        <GuidanceTools
          runner={currentRunner}
          onboardState={onboardState}
          onCloseTreasureBox={onCloseTreasureBox}
        />
      </div>
    </div>
  );
};
