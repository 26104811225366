import React, { FC, useState } from 'react';
import Skeleton from '@mui/material/Skeleton';
import { VideoType } from 'adapters/repositories/typeDefinition';

type Props = {
  video: VideoType;
  width: string;
  onClickFunc: any;
};

export const ImgWithLoading: FC<Props> = ({ video, width, onClickFunc }) => {
  const [isImgLoaded, setIsImgLoaded] = useState(false);
  const scWidth = window.parent.screen.width * 0.22;
  const thumbnailPath = video.thumbnail || video.s3_thumbnail_path || '';

  return (
    <>
      {isImgLoaded ? (
        <img src={thumbnailPath} width={width} alt="" onClick={onClickFunc}></img>
      ) : (
        <div style={{ width: width }}>
          <Skeleton
            animation="wave"
            variant="rectangular"
            width={scWidth}
            height={scWidth * 0.55}
          />
          <img src={thumbnailPath} width="0px" onLoad={() => setIsImgLoaded(true)} alt=""></img>
        </div>
      )}
    </>
  );
};
