import React from 'react';

import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Typography from '@mui/material/Typography';

const defaultSteps = ['動画を選ぶ', 'チェック & メモ', '送信'];

type uploadStepViewerProps = {
  activeStep: number;
  steps: string[];
};

export const UploadStepViewer = ({
  activeStep,
  steps = defaultSteps,
}: uploadStepViewerProps) => {
  return (
    <div
      style={{
        position: 'relative',
        width: '95%',
        top: '0px',
        backgroundColor: '#fafdff',
      }}
    >
      <Box
        sx={{
          width: '100%',
          position: 'relative',
        }}
      >
        <Stepper activeStep={activeStep}>
          {steps.map((label, index) => {
            const stepProps = {};
            if (index === activeStep) {
              return (
                <Step key={label} {...stepProps}>
                  <StepLabel>
                    <Typography
                      style={{
                        color: '#00608d',
                        fontSize: '9pt',
                        fontWeight: 'bold',
                      }}
                    >
                      {' '}
                      {label}
                    </Typography>
                  </StepLabel>
                </Step>
              );
            }
            return (
              <Step key={label} {...stepProps}>
                <StepLabel>
                  {' '}
                  <Typography
                    style={{
                      fontSize: '7pt',
                      fontWeight: 'bold',
                    }}
                  >
                    {' '}
                    {label}
                  </Typography>
                </StepLabel>
              </Step>
            );
          })}
        </Stepper>
      </Box>
    </div>
  );
};
