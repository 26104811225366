import React, { ReactNode } from 'react';
import { WorldHeader } from './WorldHeader';
import WorldFooter from './WorldFooter';

type Props = {
  children: ReactNode;
};

export const idatenWorldWrapper = ({ children }: Props) => {
  return (
    <>
      <WorldHeader />
      <main>
        {children}
        <footer>
          <WorldFooter />
        </footer>
      </main>
    </>
  );
};
