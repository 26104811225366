import React, { FC, memo } from 'react';
import { Card } from '@material-ui/core';

import {
  checkPointListJA,
  ScoreEditType,
  //eslint-diable-next-line
} from 'components/MenuConst/GradeGoalConst';

import { VideoType } from 'adapters/repositories/typeDefinition';
import { ScoreInput } from 'pages/adminPage/parttimePages/components/ScoreInput';
import { optionsForEachCkpt } from 'components/MenuConst/optionsForEachCheckPoint/CheckpointOptions';
import { checkpointDictForSelectable } from 'constants/menus/checkpoints/selectableMenuCheckpoints';
import { nationType } from 'constants/nations';
import optionDictForSelectable from 'constants/menus/optionsForEachCheckpoints';
import { returnDictType } from '../constants/spsheetURL';

type onChangeScoreType = (key: string) => (e: React.ChangeEvent<HTMLInputElement>) => void;

type Props = {
  chosenVideo: VideoType;
  onChangeScore: onChangeScoreType;
  score: ScoreEditType;
  autoCommentTemplate: returnDictType;
};

export const ManualScoringField: FC<Props> = memo((props) => {
  const { chosenVideo, onChangeScore, score, autoCommentTemplate } = props;
  const student_group = chosenVideo.upload_runner.student_group;
  const videoFilePath = chosenVideo.file || chosenVideo.s3_path.split('/videos/')[1];

  let options_shokyu = optionsForEachCkpt(chosenVideo.upload_runner, chosenVideo.grade_before);

  if (!!chosenVideo.menu) {
    const menu = chosenVideo.menu as nationType;
    const options = autoCommentTemplate[menu]['optionDict'];
    console.log(options);
    const checkpoints = Object.keys(options);

    return (
      <div>
        <video
          controls
          width="90%"
          src={`https://idaten-compressed-video.s3.amazonaws.com/video/${videoFilePath}`}
          loop
        ></video>
        <>
          {!!chosenVideo.is_exam ? (
            <Card>
              {checkpoints.map((ckpt, _) => {
                console.log(ckpt);
                return (
                  <ScoreInput
                    label={ckpt}
                    currentValue={score[ckpt] || ''}
                    onChangeScore={onChangeScore(ckpt)}
                    options={options[ckpt]}
                    key={_}
                  />
                );
              })}
              <ScoreInput
                label={'合否'}
                currentValue={score.eval || ''}
                onChangeScore={onChangeScore('eval')}
                options={['◯合格', '×不合格']}
              />
            </Card>
          ) : null}
        </>
      </div>
    );
  }

  console.log(options_shokyu);
  const checkpointList = Object.keys(options_shokyu) as checkPointListJA[]; //集合として一致すれば良い？

  return (
    <div>
      <video
        controls
        width="90%"
        src={`https://idaten-compressed-video.s3.amazonaws.com/video/${videoFilePath}`}
        loop
      ></video>
      <>
        {!!chosenVideo.is_exam ? (
          <Card>
            {checkpointList.map((ckpt, _) => {
              return (
                <ScoreInput
                  label={ckpt}
                  currentValue={score[ckpt] || ''}
                  onChangeScore={onChangeScore(ckpt)}
                  options={options_shokyu[ckpt]}
                  key={_}
                />
              );
            })}
            <ScoreInput
              label={'合否'}
              currentValue={score.eval || ''}
              onChangeScore={onChangeScore('eval')}
              options={['◯合格', '×不合格']}
            />
            <div style={{ padding: '10px' }}>今回の出来栄え：{score.eval}</div>
          </Card>
        ) : null}
      </>
    </div>
  );
});
