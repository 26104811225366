import React from 'react';
import classes from './footer.module.css';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { useNavigate } from 'react-router-dom';

export const UploadFAB = () => {
  const navigate = useNavigate();

  return (
    <div className={classes.fab}>
      <button className={classes.upload}>
        <CloudUploadIcon
          style={{ color: '#ffebcd' }}
          onClick={() => {
            navigate(`/selectable/upload`);
          }}
        />
      </button>
    </div>
  );
};
