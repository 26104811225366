export function random_16_char() {
  // 使用可能な文字を定義
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let result = '';
  const charactersLength = characters.length;
  for (let i = 0; i < 16; i++) {
    // ランダムに文字を選択して結果に追加
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}
