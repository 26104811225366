import React, { FC, memo } from 'react';
import classes from '../onboardingCarousel.module.css';
import send from 'features/selectable_course/static/send.gif';

const UploadCut: FC = memo(() => {
  return (
    <>
      <div className={classes.blackBorder} style={{ fontSize: '1rem', marginBottom: '6vw' }}>
        挑戦 & 撮影をしたら
        <br />
        コーチに動画を送ろう！
      </div>
      <div className={classes.iidukaFirstWrapper} style={{ width: '82vw' }}>
        <img src={send} alt="" width="60%"></img>
      </div>
    </>
  );
});

export default UploadCut;
