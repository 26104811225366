import React, { FC, memo } from 'react';

import { Grid, GridSize } from '@material-ui/core';

import { VideoType } from 'adapters/repositories/typeDefinition';
import { ScoreEditType } from 'components/MenuConst/GradeGoalConst';
import { SpaceLine, VerticalLine } from 'pages/ResultPage/components/StepFunctions/Lines';
import { UpOrStayBar } from 'pages/ResultPage/components/StepFunctions/UpOrStayBar';
import { MessageBar } from 'pages/ResultPage/components/StepFunctions/MessageBar';
import {
  checkpointDictForSelectable,
  scoreTypeForSelectable,
} from 'constants/menus/checkpoints/selectableMenuCheckpoints';
import { nationType } from 'constants/nations';

type Props = {
  video: VideoType;
};

export const ScoreViewForTrac: FC<Props> = memo(({ video }) => {
  if (!video.score) {
    return <div>スコアがありません</div>;
  }

  const detailCheckPoints = JSON.parse(video.score.details) as scoreTypeForSelectable;
  console.log(detailCheckPoints);
  const nation = (video.menu as nationType) || ('インド' as nationType);
  const checkPointList = checkpointDictForSelectable[nation];
  const evaluation = checkPointList.map((ckpt) => {
    return {
      title: ckpt,
      isBorderPassed: detailCheckPoints[ckpt]?.includes('◯') || false,
      isAllPassed: detailCheckPoints[ckpt]?.includes('◯') || false,
    };
  });
  const finalResult = crtFinalResultDict(detailCheckPoints);

  return (
    <>
      {evaluation.map((ckpt, idx: number) => (
        <>
          <Grid container>
            <VerticalLine idx={idx} />
            <Grid item xs={(12 - idx) as GridSize}>
              <MessageBar ckpt={ckpt} necessities={[]} />
            </Grid>
          </Grid>
          {/* 間隔分の縦線 */}
          <SpaceLine idx={idx} maxLen={evaluation.length} />
        </>
      ))}
      <div style={{ marginTop: '16px' }}>
        <UpOrStayBar result={finalResult} />
      </div>
    </>
  );
});

const crtFinalResultDict = (evaluation: ScoreEditType) => {
  if (evaluation['eval']?.includes('◯')) {
    return { title: '合格', isAllPassed: true, isBorderPassed: true };
  }

  return { title: 'もう一回', isAllPassed: false, isBorderPassed: false };
};
