import { RunnerType } from 'adapters/repositories/typeDefinition';
import { convertRunnerGrade, is10GradesPackage } from 'components/constants';
import { grades10, grades } from '../GradeGoalConst';

export const decideMessageVideoLink = (runner: RunnerType) => {
  if (
    !!runner.student_group &&
    ['TRAC', 'newtrac', 'trac', 'normal'].includes(runner.student_group)
  ) {
    const runnerGrade = convertRunnerGrade(runner);
    return introVideoDictForNewTrac[runnerGrade];
  }
  if (is10GradesPackage(runner)) {
    const runnerGrade = convertRunnerGrade(runner);
    return introVideoDictFor10Grades[runnerGrade];
  }
  const runnerGrade = convertRunnerGrade(runner) as grades;
  return introVideoDictFor20Grades[runnerGrade];
};

type sampleVideoDict10Type = {
  [key in grades10]: string;
};

type sampleVideoDictType = {
  [key in grades]: string;
};

export const introVideoDictForNewTrac: sampleVideoDict10Type = {
  10: 'https://www.youtube.com/embed/xuyZQ5XCWoE',
  9: 'https://www.youtube.com/embed/WvZgkDJewGE',
  8: 'https://www.youtube.com/embed/SlRwkDKNfp8',
  7: 'https://www.youtube.com/embed/kcMVCD95ghQ',
  6: 'https://www.youtube.com/embed/wft3B8xcXPY',
  5: 'https://www.youtube.com/embed/Pv13he78rGw',
  4: 'https://www.youtube.com/embed/yRFdEofJWmc',
  3: 'https://www.youtube.com/embed/rTzNBblRn2U',
  2: 'https://www.youtube.com/embed/1BUcWGwhpiw',
  1: 'https://www.youtube.com/embed/8-qNuULKfaI',
  0: 'https://www.youtube.com/embed/U2e8xkYuo1M',
};

export const introVideoDictFor10Grades: sampleVideoDict10Type = {
  10: 'https://www.youtube.com/embed/j681TiLRK5s',
  9: 'https://www.youtube.com/embed/gbOq5zfj5Ro',
  8: 'https://www.youtube.com/embed/8pMWFWyCy54',
  7: 'https://www.youtube.com/embed/lKGpasWDEGM',
  6: 'https://www.youtube.com/embed/6j5RGEY1ck0',
  5: 'https://www.youtube.com/embed/7wdq6cMbImY',
  4: 'https://www.youtube.com/embed/P-aqMsopq7U',
  3: 'https://www.youtube.com/embed/pJKx5liGDLk',
  2: 'https://www.youtube.com/embed/SYuUdwWu_zQ',
  1: 'https://www.youtube.com/embed/aHFYkpRmMTo',
  0: 'https://www.youtube.com/embed/nizldYtCbx0',
};

export const introVideoDictFor20Grades: sampleVideoDictType = {
  20: 'https://www.youtube.com/embed/Mxor_tTRzl0',
  19: 'https://www.youtube.com/embed/Q_mrWndGaRk',
  18: 'https://www.youtube.com/embed/WV98b-1XAV0',
  17: 'https://www.youtube.com/embed/YriFD4qJvmM',
  16: 'https://www.youtube.com/embed/6-GywvC9NUI',
  15: 'https://www.youtube.com/embed/NnB53c5rDsc',
  14: 'https://www.youtube.com/embed/cM5iQUFHwEU',
  13: 'https://www.youtube.com/embed/egM1_0-56vw',
  12: 'https://www.youtube.com/embed/bfP2vpTC8GE',
  11: 'https://www.youtube.com/embed/TkUXz0RyBYA',
  10: 'https://www.youtube.com/embed/qcHs9HDCT2I',
  9: 'https://www.youtube.com/embed/lkG3Igem5Kg',
  8: 'https://www.youtube.com/embed/K-2vZCMiVxo',
  7: 'https://www.youtube.com/embed/ASQx2TKidL8',
  6: 'https://www.youtube.com/embed/jw73nT2BCk8',
  5: 'https://www.youtube.com/embed/W5eiDdO7IU8',
  4: 'https://www.youtube.com/embed/19LXxn0u5ts',
  3: 'https://www.youtube.com/embed/H68kaIwoSCE',
  2: 'https://www.youtube.com/embed/5cqL7begsdc',
  1: 'https://www.youtube.com/embed/7W2Tap6YnPk',
  0: 'https://www.youtube.com/embed/nizldYtCbx0',
};
