import React, { FC, memo } from 'react';
import { TextInputBox } from 'pages/auth/Register/components/TextInputBox';
import { HeightInput } from 'pages/auth/Register/components/HeightInput';
import { GenderInput } from 'pages/auth/Register/components/GenderInput';
import { Grid, Button } from '@material-ui/core';
import TrialUploadButton from './UploadButton';
import { emailValidator } from 'utils/funcs/emailValidator';
import { formValidator } from './ValidateForm';
import { handleInputChange, RunnerInfoType, heightChangeFunc } from '../types';
import sendPicture from 'static/lp/third_send.png';

type Props = {
  handleBackClick: () => void;
  file: File | null;
  runnerInfo: RunnerInfoType;
  handleInfoChange: handleInputChange;
  handleHeightChange: heightChangeFunc;
};

export const InformationScreen: FC<Props> = memo(
  ({ handleBackClick, file, runnerInfo, handleInfoChange, handleHeightChange }) => {
    const isInfoComplete = formValidator(runnerInfo);

    return (
      <div style={{ textAlign: 'center' }}>
        <div style={{ margin: '16px 0 0 8px' }}>
          <img src={sendPicture} width="99%" alt="撮影方法" />
        </div>
        <div
          style={{
            fontWeight: 'bold',
            fontSize: '12pt',
            color: '#424242',
            margin: ' -20px 0 12px 0',
          }}
        >
          以下を記入して動画を送信
        </div>

        <div
          style={{
            textAlign: 'left',
            margin: '2% 0 2% 2%',
            backgroundColor: 'white',
            width: '90%',
            padding: '12px',
          }}
        >
          <TextInputBox
            value={runnerInfo.username}
            validationMsg={''}
            handleChange={handleInfoChange('username')}
            label={'おなまえ（下の名前）'}
            placeHolder={'（例）ひかる'}
          />
          <div style={{ marginTop: '8px' }}>
            <TextInputBox
              value={runnerInfo.email}
              validationMsg={emailValidator(runnerInfo.email)}
              handleChange={handleInfoChange('email')}
              label={'メールアドレス'}
            />
          </div>

          <div style={{ marginTop: '24px' }}>
            <Grid container>
              <Grid item xs={6}>
                <HeightInput handleHeightChange={handleHeightChange} height={runnerInfo.height} />
              </Grid>
              <Grid item xs={6}>
                <GenderInput gender={runnerInfo.gender} handleChange={handleInfoChange('gender')} />
              </Grid>
            </Grid>
          </div>
        </div>
        <div style={{ margin: '24px 0 0 20px' }}>
          <TrialUploadButton
            isComplete={isInfoComplete}
            color="primary"
            runnerInfo={runnerInfo}
            file={file}
            failFunc={() => {
              alert('失敗！');
            }}
          />
        </div>
        <div style={{ display: 'flex', marginTop: '40px' }}>
          <div style={{ width: '44%' }}>
            <Button onClick={handleBackClick} variant="outlined">
              {'<< 動画選択に戻る'}
            </Button>
          </div>

          <div style={{ width: '56%' }}>
            <br />
          </div>
        </div>
        <div>
          <br />
        </div>
      </div>
    );
  },
);
