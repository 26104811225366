import { RunnerType } from 'adapters/repositories/typeDefinition';
import { convertGradeByRunner } from 'components/constants';
import { optionDict_10kyu_trac } from '../GoalsForEachGrade/CheckpointsForTrac10kyu/10kyu';
import { optionDict_9kyu_trac } from '../GoalsForEachGrade/CheckpointsForTrac10kyu/9kyu';
import { optionDict_8kyu_trac } from '../GoalsForEachGrade/CheckpointsForTrac10kyu/8kyu';
import { optionDict_7kyu_trac } from '../GoalsForEachGrade/CheckpointsForTrac10kyu/7kyu';
import { optionDict_6kyu_trac } from '../GoalsForEachGrade/CheckpointsForTrac10kyu/6kyu';
import { optionDict_5kyu_trac } from '../GoalsForEachGrade/CheckpointsForTrac10kyu/5kyu';
import { optionDict_4kyu_trac } from '../GoalsForEachGrade/CheckpointsForTrac10kyu/4kyu';
import { optionDict_3kyu_trac } from '../GoalsForEachGrade/CheckpointsForTrac10kyu/3kyu';
import { optionDict_2kyu_trac } from '../GoalsForEachGrade/CheckpointsForTrac10kyu/2kyu';
import { optionDict_1kyu_trac } from '../GoalsForEachGrade/CheckpointsForTrac10kyu/1kyu';
import { grades10 } from '../GradeGoalConst';

export const optionsForEachCkpt = (runner: RunnerType, videoGrade: number) => {
  const g = convertGradeByRunner(runner, videoGrade) as grades10;
  return optionsForNewTrac[g];
};

const optionsForNewTrac = {
  10: optionDict_10kyu_trac,
  9: optionDict_9kyu_trac,
  8: optionDict_8kyu_trac,
  7: optionDict_7kyu_trac,
  6: optionDict_6kyu_trac,
  5: optionDict_5kyu_trac,
  4: optionDict_4kyu_trac,
  3: optionDict_3kyu_trac,
  2: optionDict_2kyu_trac,
  1: optionDict_1kyu_trac,
  0: optionDict_1kyu_trac,
};
