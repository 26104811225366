import React, { useEffect, FC, useState } from 'react';
import { AccountContext } from 'contexts/AccountContext';

import { PageTitle } from '../../components/common/PageTitle';
import { LevelUpMenu } from './components/LevelUpMenu';
import { decideMenu } from '../../components/MenuConst/DecideMenu';
import { menuType, menuVariationType } from './type';
import { usePracticeOnboardingModal } from 'hooks/PracticeOnboarding/usePracticeOnboardModal';
import { TabInfo } from 'components/TabInfo';
import { AnnounceBar } from './components/AnnounceBar';
import { AllMenu } from './components/AllMenu';
import { TRACMenuTab } from './components/TracMenuTab';

export const PracticePage: FC = () => {
  const { currentRunner } = React.useContext(AccountContext);

  const { forceModalOpen, OnboardModal } =
    usePracticeOnboardingModal(currentRunner);

  useEffect(() => {
    if (!currentRunner) {
      return;
    }
    const isPracticePageSeen =
      localStorage.getItem(String(currentRunner.id)) || 'yet';
    if (!!currentRunner && currentRunner.grade > 0) {
      return;
    }
    if (['yet', 'beforePractice'].includes(isPracticePageSeen)) {
      forceModalOpen(true);
    }
  }, [currentRunner]);

  return (
    <>
      <PageTitle title={'練習のときに見るページ'} />
      <TabInfo labels={['昇級メニュー', '今週の練習', 'すべて']}>
        {/* 昇級メニューのタブ */}
        <div style={{ textAlign: 'center' }}>
          <AnnounceBar currentPage="levelup" />
          <LevelUpMenu runner={currentRunner} />
          <div style={{ marginTop: '100px' }}>
            <br />
          </div>
        </div>

        {/* 今週の練習のタブ */}
        <div>
          <AnnounceBar currentPage="practice" />
          <TRACMenuTab runner={currentRunner} />
        </div>

        {/* すべての練習のタブ */}
        <div>
          {!!currentRunner && <AllMenu runner={currentRunner} />}
          <div style={{ marginTop: '100px' }}></div>
        </div>
      </TabInfo>
    </>
  );
};
