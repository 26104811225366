import { menuType } from 'pages/PracticePage/type';
import jigzag_thumb from 'components/MenuConst/thumbnails/jigzag_thumb.png';
import oneLegJump_thumb from 'components/MenuConst/thumbnails/oneLegJump_thumb.png';
import sidehop_thumb from 'components/MenuConst/thumbnails/sidehop_thumb.png';
import arm_raise_jump_thumb from 'components/MenuConst/thumbnails/arm_raise_jump_thumb.png';

import { jigzag_one_leg_jump, one_leg_jump, side_hop, side_hop_arm_raise } from './videos';

export const OneLegJump: menuType = {
  duration: 5, // 所要時間（分）を入れる
  title: 'その場片足ジャンプ',
  isInside: 'able',
  target: ['しせい', '全力走'],
  menuVariation: ['tech'],
  hitWordList: ['その場片足ジャンプ', 'かたあしジャンプ'],
  grade: 2,
  image: oneLegJump_thumb,
  video: one_leg_jump,
};

export const JigZagJump: menuType = {
  duration: 5, // 所要時間（分）を入れる
  title: '片足ジグザグジャンプ',
  isInside: 'able',
  target: ['しせい', '全力走'],
  menuVariation: ['tech'],
  hitWordList: ['片足ジグザグジャンプ', 'かたあしジグザグ'],
  grade: 2,
  image: jigzag_thumb,
  video: jigzag_one_leg_jump,
};

export const SideHop: menuType = {
  duration: 5, // 所要時間（分）を入れる
  title: 'サイドケンケン',
  isInside: 'able',
  target: ['しせい', '全力走'],
  menuVariation: ['tech'],
  hitWordList: ['サイドケンケン'],
  grade: 2,
  image: sidehop_thumb,
  video: side_hop,
};

export const SideHopArmRaise: menuType = {
  duration: 5, // 所要時間（分）を入れる
  title: '片足腕上げジャンプ #昇級テスト',
  isInside: 'able',
  target: ['しせい', '全力走'],
  menuVariation: ['tech'],
  hitWordList: ['片足腕上げジャンプ'],
  grade: 2,
  image: arm_raise_jump_thumb,
  video: side_hop_arm_raise,
};
