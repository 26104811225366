import React, { FC } from 'react';

import '../../../components/components.css';
import classes from './videoPlayer.module.css';
import loadingGif from '../../../static/running-101-gif.gif';
import { VideoType } from 'adapters/repositories/typeDefinition';

type Props = {
  isLoading: boolean;
  videoInfo: VideoType;
};

export const VideoPlayer: FC<Props> = ({ isLoading, videoInfo }) => {
  const [isLightFileReady, setIsLightFileReady] = React.useState(false);
  console.log('light ready=', isLightFileReady);
  const thumbnailPath = videoInfo.thumbnail || videoInfo.s3_thumbnail_path || '';
  React.useEffect(() => {
    const videoElem = document.getElementById('videoHidden');
    // console.log(videoElem);
    if (!!videoElem && !isLightFileReady) {
      console.log('light-videoload');
      videoElem.addEventListener('loadedmetadata', () => {
        console.log('loaded');
        setIsLightFileReady(true);
      });
    }
  }, [isLightFileReady]);

  if (isLoading) {
    return (
      <div>
        ビデオをロード中
        <img src={loadingGif} height="150" width="auto" alt="" />
      </div>
    );
  }

  if (!videoInfo.file) {
    // この場合は違うバケットに保存されている.
    const filePath = videoInfo.s3_path;
    const fileID = filePath.split('/videos/')[1];
    return (
      <div className={classes.videoWrapper}>
        {isLightFileReady ? (
          <video
            style={{ margin: '3px' }}
            className={classes.videoBasic}
            src={`https://idaten-compressed-video.s3.amazonaws.com/video/${fileID}`}
            controls
            muted
            loop
            poster={thumbnailPath}
          ></video>
        ) : (
          <video
            style={{ margin: '3px' }}
            className={classes.videoBasic}
            src={filePath}
            controls
            muted
            loop
            poster={`${thumbnailPath}`}
          ></video>
        )}
        <video
          style={{ margin: '3px' }}
          id="videoHidden"
          className={classes.hidden}
          src={`https://idaten-compressed-video.s3.amazonaws.com/video/${fileID}`}
          muted
          poster={thumbnailPath}
        ></video>

        <br />
      </div>
    );
  }

  return (
    <div className={classes.videoWrapper}>
      {isLightFileReady ? (
        <video
          style={{ margin: '3px' }}
          className={classes.videoBasic}
          src={`https://idaten-compressed-video.s3.amazonaws.com/video/${videoInfo.file}`}
          controls
          muted
          loop
          poster={videoInfo.thumbnail}
        ></video>
      ) : (
        <video
          style={{ margin: '3px' }}
          className={classes.videoBasic}
          src={`https://shimotakaido.s3.amazonaws.com/static/${videoInfo.file}`}
          controls
          muted
          loop
          poster={thumbnailPath}
        ></video>
      )}
      <video
        style={{ margin: '3px' }}
        id="videoHidden"
        className={classes.hidden}
        src={`https://idaten-compressed-video.s3.amazonaws.com/video/${videoInfo.file}`}
        muted
        poster={thumbnailPath}
      ></video>

      <br />
    </div>
  );
};
