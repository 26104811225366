import {
  ScoreEditType, //eslint-diable-next-line
} from 'components/MenuConst/GradeGoalConst';

import { RunnerType } from 'adapters/repositories/typeDefinition';
import { returnDictType } from 'features/scoring/constants/spsheetURL';
import { nationType } from 'constants/nations';

function crtComment(
  runnerInfo: RunnerType,
  score: ScoreEditType,
  yobina: string,
  checkPointDict: returnDictType,
  nation: nationType,
) {
  const shumoku = checkPointDict[nation]['shumoku'];
  const setence1 = crt_line1(score, yobina, shumoku, nation);
  const sentence2 = crt_line2(runnerInfo, score, yobina, checkPointDict, nation);
  const sentence3 = crt_line3(score);
  return setence1 + '<<paragraph>>' + sentence2 + '<<paragraph>>' + sentence3 + '<<paragraph>>';
}

function crt_line1(score: ScoreEditType, yobina: string, shumoku: string, nation: nationType) {
  const head1 = yobina + '、';
  const head2 = `今回の動画は${nation}の試験内容である「${shumoku}」`;
  let evaluation =
    score.eval === '◯合格'
      ? 'が大変良くできています！合格おめでとうございます！'
      : 'に少しだけ課題があります。';
  return head1 + head2 + evaluation;
}

function crt_line2(
  runnerInfo: RunnerType,
  score: ScoreEditType,
  yobina: string,
  checkPointDict: returnDictType,
  nation: nationType,
) {
  const compliment = crtCompliment(score, yobina, checkPointDict, nation);
  const growthPotential = crtGrowhPotential(score, yobina, checkPointDict, nation);
  return compliment + growthPotential;
}

function crtCompliment(
  score: unknown,
  yobina: string,
  checkPointDict: returnDictType,
  nation: nationType,
) {
  const complimentDict = checkPointDict[nation]['complimentData'];
  const ckptKeys = Object.keys(complimentDict);
  const markedScore = score as { [key in typeof ckptKeys[number]]: string };
  const goodPointList = ckptKeys.filter(
    (ckpt) => !!markedScore[ckpt] && markedScore[ckpt]?.indexOf('◯') !== -1,
  );
  let compliment = '';
  if (goodPointList.length < 1) {
    compliment += `今回のテストはすこし難しかったかな？ただ、それはその分のびしろが大きいってこと！今からコーチが言う伸びしろポイントをしっかり復習すれば次はきっと合格できるよ！`;
    return compliment;
  }
  compliment += `まず${yobina}の良い点として${complimentDict[goodPointList[0]]}`;
  if (goodPointList.length < 2) {
    return compliment;
  }
  compliment += `また、さらに良かった点として${
    complimentDict[goodPointList[goodPointList.length - 1]]
  }`;
  return compliment;
}

function crt_line3(score: ScoreEditType) {
  let evaluation =
    score.eval === '◯合格'
      ? '今回はとても良い出来栄えでしたね！この調子で新しい練習も楽しみながらがんばってみてください！'
      : '####いい具合になぐさめる####';
  return evaluation;
}

function crtGrowhPotential(
  score: ScoreEditType,
  yobina: string,
  checkPointDict: returnDictType,
  nation: nationType,
) {
  console.log(checkPointDict);
  const nobishiroData = checkPointDict[nation]['nobishiroData'];
  console.log(nobishiroData);
  const nobishiro_options = Object.keys(nobishiroData);
  const shortcoming = nobishiro_options.find((nobishiro) =>
    Object.values(score).includes(nobishiro),
  );
  let message = '';
  if (!shortcoming) {
    message += `今回のテストは文句なしの満点合格です！これからも${yobina}のいい動きを見るのを楽しみにしていますよ！`;
    return message;
  }
  message += `そんな${yobina}の伸びしろポイントはズバリ${[nobishiroData[shortcoming]]}`;

  return message;
}

export default crtComment;
