/////////////////////////////
// 動画送信ボタンを制御するフック
// 要件としては、
// 1. 送信ボタンと送信中のステータスを持つ
// 2. APIを叩き、もしビデオが他にあればReplaceされる旨を表示
/////////////////////////////

import { ReplaceVideoView } from 'pages/LevelUpPage/components/ReplaceVideoPage';
import React from 'react';
import { AccountContext } from 'contexts/AccountContext';
import { useGetVideoByRunner } from 'hooks/useGetVideoByRunner';

type Props = {
  formContent: string;
  file: File | undefined;
  completeFunc: () => void;
  failFunc: () => void;
  activeStep: number;
  country: string;
};

export const useVideoSendButton = (props: Props) => {
  const { file, formContent, country } = props;
  const { accountInfo, currentRunner } = React.useContext(AccountContext);
  const { videoList } = useGetVideoByRunner(currentRunner);

  const params = new FormData();
  if (!!file) {
    params.append('content', file);
    params.append('fileName', file.name);
    params.append('uploaded_by', accountInfo?.id || 'test');
    params.append('upload_runner', currentRunner?.id || 'test');
    params.append('is_exam', 'true');
    params.append('text', formContent);
    params.append('menuText', 'デフォルト');
    params.append('menu', country);
  }
  console.log(props);

  return {
    sendButton: <ReplaceVideoView videos={videoList} params={params} {...props} />,
  };
};
