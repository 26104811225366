import React, { FC } from 'react';
import { FirstView } from './FirstView';
import { SecondView } from './SecondView';
import { ThirdView } from './ThirdView';
import { FourthView } from './FourthView';
import { FifthView } from './FifthView';
import { SixthView } from './SixthView';
import { SeventhView } from './SeventhView';
import { EighthView } from './EighthView';
import { NinthView } from './NinthView';

import classes from './onboardingCarousel.module.css';
import { RunnerType } from 'adapters/repositories/typeDefinition';
import {
  NextButton,
  NextAndBackButton,
  FinalButton,
} from 'hooks/PracticeOnboarding/components/NextButton';
import { ProgressBar } from './ProgressBar';
import FirstCut from './selectable/FirstCut';
import SecondCut from './selectable/SecondCut';
import ThirdCut from './selectable/ThirdCut';
import FourthCut from './selectable/FourthCut';
import FinalCut from './selectable/FinalCut';
import GorinCut from './selectable/GorinCut';
import SixthCut from './selectable/SixthCut';
import ResultCut from './selectable/ResultCut';
import UploadCut from './selectable/UploadCut';

type Props = {
  handleClose: () => void;
  runner: RunnerType;
  isSelectable: boolean;
};

export const OnboardingCarousel: FC<Props> = ({ handleClose, runner, isSelectable }) => {
  const [cellNum, setCellNum] = React.useState<number>(0);
  const pageNum = isSelectable ? 9 : 8;
  const progress = Math.ceil((cellNum / pageNum) * 100);

  return (
    <div>
      <div>
        <div
          style={{
            textAlign: 'center',
            height: '52%',
          }}
        >
          <div style={{ width: '88%', marginLeft: '5%', marginBottom: '30px' }}>
            <ProgressBar progress={progress} />
          </div>

          {cellNum === 0 && (
            <>
              <div className={classes.modalContentWrapper}>
                {(isSelectable && <FirstCut runner={runner} />) || <FirstView runner={runner} />}
              </div>
              <div style={{ margin: '8px', textAlign: 'center' }}>
                <NextButton handleClick={() => setCellNum(cellNum + 1)} />
              </div>
            </>
          )}
          {cellNum === 1 && (
            <>
              <div className={classes.modalContentWrapper}>
                {(isSelectable && <SecondCut runner={runner} />) || <SecondView runner={runner} />}
              </div>
            </>
          )}
          {cellNum === 2 && (
            <>
              <div className={classes.modalContentWrapper}>
                {(isSelectable && <ThirdCut runner={runner} />) || <ThirdView runner={runner} />}
              </div>
            </>
          )}
          {cellNum === 3 && (
            <>
              <div className={classes.modalContentWrapper}>
                {(isSelectable && <FourthCut />) || <FourthView />}
              </div>
            </>
          )}
          {cellNum === 4 && (
            <>
              <div className={classes.modalContentWrapper}>
                {(isSelectable && <SixthCut />) || <FifthView />}
              </div>
            </>
          )}
          {cellNum === 5 && (
            <>
              <div className={classes.modalContentWrapper}>
                {(isSelectable && <UploadCut />) || <SixthView />}
              </div>
            </>
          )}
          {cellNum === 6 && (
            <>
              <div className={classes.modalContentWrapper}>
                {(isSelectable && <ResultCut />) || <SeventhView />}
              </div>
            </>
          )}
          {cellNum === 7 && (
            <>
              <div className={classes.modalContentWrapper}>
                {(isSelectable && <GorinCut />) || <EighthView runner={runner} />}
              </div>
            </>
          )}
          {isSelectable && cellNum === 8 && (
            <>
              <div className={classes.modalContentWrapper}>
                <EighthView runner={runner} />
              </div>
            </>
          )}
          {cellNum === pageNum && (
            <>
              <div className={classes.modalContentWrapper}>
                {(isSelectable && <FinalCut />) || <NinthView />}
              </div>

              <div style={{ margin: '8px', textAlign: 'center' }}>
                {(isSelectable && (
                  <FinalButton
                    message="はじめる"
                    handleFinalClick={handleClose}
                    handleBackClick={() => setCellNum(cellNum - 1)}
                  />
                )) || (
                  <FinalButton
                    message="たのしむ！"
                    handleFinalClick={handleClose}
                    handleBackClick={() => setCellNum(cellNum - 1)}
                  />
                )}
              </div>
            </>
          )}
          {cellNum > 0 && cellNum < pageNum && (
            <div style={{ margin: '8px', textAlign: 'center' }}>
              <NextAndBackButton
                handleForwardClick={() => setCellNum(cellNum + 1)}
                handleBackClick={() => setCellNum(cellNum - 1)}
              />
            </div>
          )}
        </div>
        <div>
          <br />
        </div>
      </div>
    </div>
  );
};
