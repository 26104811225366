import { PageTitle } from 'components/common/PageTitle';
import { useActiveStep } from 'pages/LevelUpPage/hooks/useActiveStepViewer';
import { useFormComponent } from 'pages/LevelUpPage/hooks/useFormInput';
import { useVideoUploadComponent } from 'pages/UploadPage/hooks/useVideoUpload';
import React, { useEffect, useState } from 'react';
import useGoalSelecter from './hooks/useGoalSelect';
import { useVideoSendButton } from 'features/upload/hooks/useVideoSendButton';
import { Alert, Snackbar } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const UploadScreen = () => {
  const steps = ['メニューを選択', '動画を選択\nメモ', '送信！'];
  const navigate = useNavigate();

  const { activeStep, setActiveStep, UploadStepViewer } = useActiveStep(steps);
  const onChooseVideo = React.useCallback(() => {
    setActiveStep(2);
  }, []);
  const { file, uploadButton } = useVideoUploadComponent({
    onChooseVideo: onChooseVideo,
  });
  const { formContent, formInput } = useFormComponent();
  const { islandSelecter, menuSelecter, exam } = useGoalSelecter();
  const [snackBarOpen, setSnackBarOpen] = useState<boolean>(false);
  const completeFunc = () => {
    setActiveStep(0);
    setSnackBarOpen(true);
  };
  const onCloseSnackBar = () => {
    setSnackBarOpen(false);
    navigate('/selectable/videolist/');
  };
  const failFunc = () => {
    setActiveStep(0);
    alert('送信失敗');
  };
  const country = exam?.country || '';
  console.log(country);
  const props = {
    file,
    formContent,
    completeFunc,
    activeStep,
    country,
    failFunc,
  };
  const { sendButton } = useVideoSendButton(props);
  console.log(exam);

  useEffect(() => {
    setActiveStep((!!exam && 1) || 0);
    return;
  }, [exam]);

  useEffect(() => {
    setActiveStep((!!file && !!formContent && 2) || 1);
    return;
  }, [formContent]);

  return (
    <>
      <PageTitle title={'テストをうける'} />
      <div
        style={{
          textAlign: 'center',
          padding: '5px',
        }}
      >
        {UploadStepViewer}
        <div style={{ display: 'flex', marginTop: '12px' }}>
          <div style={{ width: '40vw', marginRight: '4vw' }}>{islandSelecter}</div>
          <div style={{ width: '40vw' }}>{menuSelecter}</div>
        </div>
        {!!exam && (
          <div style={{ display: 'flex', margin: '12px' }}>
            <div>{exam.country}の練習！</div>
            <div style={{ width: '24px' }}>
              <img src={exam.thumbnail} width="100%" alt="" />
            </div>
          </div>
        )}
        <div>
          {!!exam && (
            <div
              style={{
                padding: '5px',
                backgroundColor: '#fff',
                width: '99%',
                height: `${window.parent.screen.width * 0.55}px`,
              }}
            >
              <iframe
                width="95%"
                height="95%"
                src={exam.videoLink + '?rel=0&modestbranding=1'}
                title="YouTube video player"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                style={{ marginBottom: '8px' }}
              ></iframe>
            </div>
          )}
        </div>
        <div>{uploadButton}</div>
        <div
          style={{
            textAlign: 'center',
            padding: '5px',
          }}
        >
          {activeStep >= 1 && <>{formInput}</>}
        </div>
        <div>{sendButton}</div>
        <Snackbar open={snackBarOpen} autoHideDuration={3000} onClose={onCloseSnackBar}>
          <Alert onClose={onCloseSnackBar} severity="success" sx={{ width: '100%' }}>
            送信が完了しました！
          </Alert>
        </Snackbar>
        <div style={{ marginTop: '100px' }}>
          <br />
        </div>
      </div>
    </>
  );
};

export default UploadScreen;
