import React, { FC, memo } from 'react';

import { Grid } from '@material-ui/core';
import { VideoLabel } from 'pages/adminPage/bossPages/components/VideoLabel';
import { VideoType } from 'adapters/repositories/typeDefinition';
import { convertGradeByRunner, convertRunnerGrade } from 'components/constants';
import { formatDateFromString } from 'utils/funcs/formatTime';

type Props = {
  video: VideoType;
  onClickFunc: (v: VideoType) => () => void;
};

export const VideoCard: FC<Props> = memo(({ video, onClickFunc }) => {
  return (
    <div id="videoCard">
      <Grid container>
        <Grid item xs={2}>
          <div onClick={onClickFunc(video)}>
            <img alt="" width="90%" src={`${video.thumbnail}`}></img>
          </div>
        </Grid>

        <Grid item xs={2}>
          <div style={{ textAlign: 'left' }}>
            <div style={{ fontSize: '9pt' }}>
              <div>{formatDateFromString(video.uploaded_date)}</div>

              <div>
                ランナーの級:
                {convertRunnerGrade(video.upload_runner)}
              </div>

              <div>名前:{video.upload_runner.username}</div>
              <div>
                所属:
                {!!video.upload_runner?.student_group
                  ? video.upload_runner?.student_group
                  : '記入なし'}
              </div>
              {!!video.menu && (
                <div>
                  挑戦メニュー:&nbsp;
                  {video.menu}
                </div>
              )}

              <div style={{ fontSize: '8pt' }}>{video.uploaded_by.email}</div>

              <div>
                {video.upload_runner.is_subscribed && (
                  <span style={{ color: 'red', fontSize: '10pt' }}>有料会員</span>
                )}
              </div>
            </div>
          </div>
        </Grid>
        <Grid item xs={2} style={{ padding: '4px', textAlign: 'left' }}>
          <div
            style={{
              textAlign: 'left',
              fontSize: '9pt',
              fontWeight: 'bold',
            }}
          >
            ----コメント----
          </div>
          <div style={{ fontSize: '8pt' }}>{video.video_memo}</div>
        </Grid>
        <Grid item xs={5} style={{ padding: '4px' }}>
          <div
            style={{
              textAlign: 'left',
              fontSize: '9pt',
              fontWeight: 'bold',
            }}
          >
            ----採点者からのコメント----
          </div>

          <div style={{ fontSize: '8pt', textAlign: 'left' }}>
            {!!video.score ? video.score.coach_comment : 'null'}
          </div>
        </Grid>
        <Grid item xs={1}>
          <VideoLabel video={video} />

          <div style={{ fontSize: '8pt' }}>
            <div>
              ビデオ:
              {convertGradeByRunner(video.upload_runner, video.grade_before)}級
              <br />
              ビデオST:
              {String(video.stay_times_before)}
            </div>
            <div>
              ランナーST：
              {video.upload_runner.stay_times}
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
});
