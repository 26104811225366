import {
  FormControl,
  InputLabel,
  MenuItem,
  MenuItemProps,
  Select,
  SelectChangeEvent,
} from '@mui/material';
import { continentMenuDict } from 'features/selectable_training/menus/menuConst';
import { islandExamType } from 'features/selectable_training/types';
import { convertContinentName } from 'features/selectable_course/funcs/convertIslandName';
import { islandType } from 'features/selectable_course/types';
import React, { useEffect, useState } from 'react';

const continentOptions: islandType[] = [
  'asia',
  'america',
  'europe',
  'oceania',
  'africa',
];

const useGoalSelecter = () => {
  const [island, setIsland] = useState<islandType>('asia');
  const [exam, setExam] = useState<islandExamType | undefined>(undefined);

  useEffect(() => {
    setExam(undefined);
  }, [island]);

  const handleChangeIsland = (event: SelectChangeEvent) => {
    console.log(event.target.value);
    setIsland(event.target.value as islandType);
  };

  const handleChangeExam = (event: SelectChangeEvent<MenuItemProps>) => {
    console.log(event.target.value);
    setExam(event.target.value as islandExamType);
  };

  const examOptions = continentMenuDict[island];

  return {
    islandSelecter: (
      <>
        <Select value={island} label="大陸" onChange={handleChangeIsland}>
          {continentOptions.map((c) => (
            <MenuItem value={c}>{convertContinentName(c)}</MenuItem>
          ))}
        </Select>
      </>
    ),
    menuSelecter: (
      <>
        <FormControl style={{ minWidth: '30vw' }}>
          <InputLabel id="demo-simple-select-label">メニュー</InputLabel>
          <Select
            value={exam}
            label="メニュー"
            onChange={handleChangeExam}
            labelId="demo-simple-select-label"
            id="demo-simple-select"
          >
            {examOptions.map((c) => (
              // eslint-disable-next-line
              <MenuItem value={c} key={c.title}>
                {c.title}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </>
    ),
    exam: exam,
  };
};

export default useGoalSelecter;
