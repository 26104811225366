import { ScoreEditType } from 'components/MenuConst/GradeGoalConst';
import { VideoType } from 'adapters/repositories/typeDefinition';
import { nationType } from 'constants/nations';
import { returnDictType } from 'features/scoring/constants/spsheetURL';
import crtComment from './crtCommentForSelectables';

export const autoCommentForSelectablePkg = (
  chosenVideo: VideoType,
  score: ScoreEditType,
  checkPointDict: returnDictType,
) => {
  const menu = chosenVideo.menu as nationType;
  if (!menu) {
    throw new Error('メニューとして国が登録されていません');
  }
  const runnerInfo = chosenVideo.upload_runner;
  const runnerName = runnerInfo.username;
  const keisyo = runnerInfo.gender === '男性' ? 'くん' : 'ちゃん';
  const yobina = runnerName + keisyo;
  let comment = crtComment(runnerInfo, score, yobina, checkPointDict, menu);

  return comment + crt_line4(chosenVideo.video_memo);
};

function crt_line4(video_memo: string | undefined) {
  if (!video_memo) {
    return '来週からはよかったら質問してね！！';
  }

  return `コメントの「${video_memo}」という点ですが、`;
}
