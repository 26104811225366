import React, { useEffect, useState } from 'react';
import classes from '../components/styles/wave.module.css';

import { islandType } from '../types';
import world_map_img from '../static/world_map.png';
import america_text from '../static/america_text.png';
import asia_text from '../static/asia_text.png';
import africa_text from '../static/africa_text.png';
import europe_text from '../static/europe_text.png';
import japan_text from '../static/japan_text.png';
import runnerGif from '../static/runner.gif';
import oceania_text from '../static/oceania_text.png';
import GuidanceTools from '../components/GuidanceTools';
import fukidashi from '../static/comment.png';
import { useFirstOnboardingModal } from 'hooks/FirstOnboarding/useFirstOnboardingModal';
import { useFetchAccount } from 'pages/MemberHome/hooks/useFetchAccount';

export const SelectableTopView = () => {
  const { currentRunner } = useFetchAccount();
  const [selectedIsland, setSelectedIsland] = useState<islandType>('start');
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const { forceModalOpen, OnboardModal } = useFirstOnboardingModal(currentRunner, true);

  useEffect(() => {
    if (!currentRunner) {
      return;
    }
    const runnerId_selectable = String(currentRunner.id) + '_selectable';
    if (localStorage.getItem(runnerId_selectable) === 'seen') {
      return;
    }
    forceModalOpen(true);
    console.log('モーダル');
  }, [currentRunner]);

  const handleTap = (event: React.TouchEvent<HTMLDivElement>) => {
    const { clientX, clientY } = event.touches[0];
    if (clientX > window.innerWidth * 0.92) {
      return;
    }
    setPosition({ x: clientX, y: clientY });
  };

  useEffect(() => {
    // 上辺の座標を利用する処理
    const element = document.getElementById('map');
    if (element) {
      const { top } = element.getBoundingClientRect();
      console.log(top);
      setPosition({ x: 0.83 * window.innerWidth, y: top + 60 });
    }
    return;
  }, []);

  return (
    <div>
      {OnboardModal}
      <div style={{ marginBottom: '0.1vw', fontSize: '6pt' }}>
        <br />
      </div>
      <div className={classes.fukidashiBig}>
        <div className={classes.fukidashiBigImg}>
          <img src={fukidashi} width="100%" alt="comment" />
        </div>
        <div className={classes.fukidashiBigContent}>
          世界の地域 / 国々をまわって
          <br />
          速く走るための練習に挑戦しよう！
        </div>
      </div>

      <div
        className={classes.sailingIcon}
        style={{
          top: position.y,
          left: position.x,
          zIndex: 5,
        }}
      >
        <div>
          <img src={runnerGif} width="30px" alt="走る" />
        </div>
      </div>

      <div className={classes.mapwrapper} onTouchStart={handleTap} id="map">
        <img className={classes.map} src={world_map_img} alt="map" />
        <div
          className={classes.northAmerica}
          onClick={() => {
            setSelectedIsland('america');
          }}
        >
          <img className={classes.textImg} src={america_text} alt="america" />
        </div>
        <div
          className={classes.europe}
          onClick={() => {
            setSelectedIsland('europe');
          }}
        >
          <img className={classes.textImg} src={europe_text} alt="europe" />
        </div>
        <div
          className={classes.oceania}
          onClick={() => {
            setSelectedIsland('oceania');
          }}
        >
          <img className={classes.textImg} src={oceania_text} alt="oceania" />
        </div>
        <div
          className={classes.japan}
          onClick={() => {
            setSelectedIsland('start');
          }}
        >
          <img className={classes.textImg} src={japan_text} alt="japan" />
        </div>
        <div
          className={classes.asia}
          onClick={() => {
            setSelectedIsland('asia');
          }}
        >
          <img className={classes.textImg} src={asia_text} alt="asia" />
        </div>
        <div
          className={classes.africa}
          onClick={() => {
            setSelectedIsland('africa');
          }}
        >
          <img className={classes.textImg} src={africa_text} alt="africa" />
        </div>
      </div>
      <GuidanceTools island={selectedIsland} runner={currentRunner} />
    </div>
  );
};
