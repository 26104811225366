import { Box } from '@material-ui/core';
import { convertContinentName } from 'features/selectable_course/funcs/convertIslandName';
import { islandType } from 'features/selectable_course/types';
import { usePlaylistWatchModal } from 'pages/PracticePage/hooks/usePlaylistWatchModal';
import { menuType } from 'pages/PracticePage/type';
import React, { FC, memo, useState } from 'react';
import decidePractices from './menus/decideMenuForContinent';
import { MenuCard } from 'pages/PracticePage/components/MenuCard';

type Props = {
  island: string | undefined;
};

const PracticeScreen: FC<Props> = memo(({ island }) => {
  const [selectedMenu, setSelectedMenu] = useState<menuType | undefined>(
    undefined,
  );
  const continent = (island as islandType) || ('start' as islandType);
  //   const { currentRunner } = React.useContext(AccountContext);
  const { forceModalOpen, MenuWatchModal } =
    usePlaylistWatchModal(selectedMenu);

  const handleMenuClick = (m: menuType) => () => {
    forceModalOpen(true);
    setSelectedMenu(m);
  };

  const menu = decidePractices(continent);

  return (
    <>
      <Box style={{ textAlign: 'center' }}>
        <div style={{ margin: '12px' }}>
          ~ {convertContinentName(continent)} ~
        </div>
        <div>
          {MenuWatchModal}
          {menu.map((m) => {
            return (
              <MenuCard menuPlaylist={m} handleMenuClick={handleMenuClick(m)} />
            );
          })}
        </div>
      </Box>
    </>
  );
});

export default PracticeScreen;
