import React, { FC } from 'react';
import { MyCircularProgress } from './CircularProgress';
import classes from './progressbar.module.css';
import { completedContriesType } from 'adapters/repositories/typeDefinition';
import { continentNationDict, continentType, continentWithExam } from 'constants/nations';
import { extractCompletedNations } from './BarGraph';

type Props = {
  countriesDict: completedContriesType;
};

const OlympicMark: FC<Props> = ({ countriesDict }) => {
  let continentAchievementDict = crtContinentAchievement(countriesDict);

  //   continentAchievementDict = {
  //     asia: 65,
  //     africa: 100,
  //     oceania: 50,
  //     europe: 32,
  //     america: 70,
  //   };

  return (
    <>
      <div className={classes.progressCollectionWrapper}>
        <div style={{ display: 'flex' }}>
          <div className={classes.oceaniaProgress}>
            <MyCircularProgress
              percent={continentAchievementDict['oceania'] || 0}
              strokeColor="#00bcd4"
              bgColor="#c3c8c8"
            />
          </div>
          <div className={classes.africaProgress}>
            <MyCircularProgress
              percent={continentAchievementDict['africa'] || 0}
              strokeColor="#000000"
              bgColor="#d6d6d6"
            />
          </div>
          <div className={classes.asiaProgress}>
            <MyCircularProgress
              percent={continentAchievementDict['asia'] || 0}
              strokeColor="#ff0000"
              bgColor="#edcaca"
            />
          </div>
        </div>
        <div style={{ display: 'flex' }}>
          <div className={classes.northAmericaProgress}>
            <MyCircularProgress
              percent={continentAchievementDict['america'] || 0}
              strokeColor="#ffd700"
              bgColor="#e2e2be"
            />
          </div>
          <div className={classes.europeProgress}>
            <MyCircularProgress
              percent={continentAchievementDict['europe'] || 0}
              strokeColor="#008000"
              bgColor="#b8f5de"
            />
          </div>
        </div>
      </div>
    </>
  );
};

type retObjType = {
  [key in continentType]?: number;
};
function crtContinentAchievement(countriesDict: completedContriesType): retObjType {
  const completedNations = extractCompletedNations(countriesDict);
  let retObj: retObjType = {};
  continentWithExam.forEach((continent) => {
    const nationsOfAnArea = continentNationDict[continent];
    const completedNumber = nationsOfAnArea.filter((n) => completedNations.includes(n)).length;
    retObj[continent] = Math.floor((completedNumber / nationsOfAnArea.length) * 100);
  });
  return retObj;
}

export default OlympicMark;
