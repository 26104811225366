import React, { FC } from 'react';
import { islandType } from '../../types';
import { Box } from '@mui/material';
import iiduka from '../../static/onishi.png';
import fukidashi from '../../static/comment.png';
import {
  continentToJenreDict,
  convertContinentName,
} from 'features/selectable_course/funcs/convertIslandName';
import { Dumbbell } from './Dumbell';
import classes from './guidanceTools.module.css';
import { MailBox } from './Mailbox';
import { PlayButton } from './PlayButton';
import { RunnerType } from 'adapters/repositories/typeDefinition';

type Props = {
  island: islandType;
  runner: RunnerType | undefined;
};
const GuidanceTools: FC<Props> = ({ island, runner }) => {
  const continent = convertContinentName(island);
  const jenre = continentToJenreDict[island];
  console.log(runner);
  return (
    <Box style={{ marginTop: '-10vw' }}>
      <div style={{ display: 'flex' }}>
        <div
          style={{
            width: '30vw',
            padding: '1vw',
            marginTop: '-13vw',
            zIndex: 2,
          }}
        >
          <img src={iiduka} alt="map" width="100%" />
        </div>
        <div className={classes.fukidashiWrapper}>
          <div className={classes.fukidashiImg}>
            <img src={fukidashi} width="100%" alt="comment" />
          </div>
          <div className={classes.fukidashiContent}>
            {continent}では速く走るための
            <br />
            {jenre}を練習するよ！
          </div>
        </div>
      </div>

      <Box>
        <div style={{ display: 'flex' }}>
          <Dumbbell island={island} />
          <MailBox examVideo={runner?.latest_exam_video[0]} />
          <PlayButton island={island} />
        </div>
      </Box>
      <div>
        <br />
      </div>
    </Box>
  );
};

export default GuidanceTools;
