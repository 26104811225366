import { RunnerType } from 'adapters/repositories/typeDefinition';
import React, { FC, memo } from 'react';
import joy_boy from 'static/materials/joy_boy.png';
import classes from '../onboardingCarousel.module.css';
import iiduka from 'features/selectable_course/static/onishi.png';

type Props = {
  runner: RunnerType;
};

const ThirdCut: FC<Props> = memo(({ runner }) => {
  return (
    <>
      <div className={classes.blackBorder} style={{ fontSize: '0.96rem' }}>
        ここからはIDATEN-Worldの <br />
        流れを説明するよ！
      </div>
      <div className={classes.iidukaFirstWrapper}>
        <img src={iiduka} alt="" width="110%"></img>
      </div>
    </>
  );
});

export default ThirdCut;
