import React, { FC } from 'react';
import { Card, Grid, CardActionArea } from '@material-ui/core';
import { menuType, isInsideType } from '../type';

type MenuCardProps = {
  menuPlaylist: menuType;
  handleMenuClick: () => void;
};

export const MenuCard: FC<MenuCardProps> = ({ menuPlaylist, handleMenuClick }) => {
  return (
    <Card style={{ width: '94%', margin: '0 0 8px 3%' }}>
      <Grid container style={{ marginTop: '6px' }}>
        <Grid item xs={4}>
          <div style={{ padding: '3px' }}>
            <CardActionArea>
              <img
                alt=""
                width="96%"
                src={String(menuPlaylist.image)}
                onClick={handleMenuClick}
              ></img>
            </CardActionArea>
          </div>
        </Grid>
        <Grid item xs={8}>
          <Grid container>
            <Grid item xs={9}>
              <div style={{ fontWeight: 'bold', fontSize: '11pt' }}>{menuPlaylist.title}</div>
              <div style={{ fontSize: '9pt', marginTop: '2px' }}>
                {formatIsInside(menuPlaylist.isInside)}
              </div>
            </Grid>
            <Grid item xs={3}>
              <div>
                {menuPlaylist.target?.map((t, idx) => {
                  return (
                    <div
                      style={{
                        borderRadius: '20px',
                        fontSize: '6pt',
                        border: '1px solid #6495ed',
                        backgroundColor: '#6495ed',
                        color: 'white',
                        textAlign: 'center',
                        width: '80%',
                        marginBottom: '4px',
                        fontWeight: 'bold',
                      }}
                      key={idx}
                    >
                      {t}
                    </div>
                  );
                })}
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Card>
  );
};

const formatIsInside = (isInside: isInsideType | undefined) => {
  if (isInside === 'able') {
    return '🏠 家でもできる！';
  }

  if (isInside === 'half') {
    return '🏠 一部家でもできる！';
  }

  return '🌤 外で取り組もう！';
};
