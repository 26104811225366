import React, { FC, memo, useCallback } from 'react';
import { CustomizedModalWrapper } from 'components/modals/ModalWrapper';
import treasure_box_open from 'static/materials/treasure_box_open.png';
import { islandType } from '../types';
import { useWindowSize } from 'hooks/useWindowSize';
import introVideoIslandDict from '../constants/introVideoIslandDict';
import { continentToJenreDict } from '../funcs/convertIslandName';

export const useIslandVideoModal = (island: islandType, handleBoxClose: () => void) => {
  const [open, setOpen] = React.useState(false);

  const toggleModalOpen = () => {
    setOpen(!open);
  };

  const forceModalOpen = useCallback((openOrClose: boolean) => {
    setOpen(openOrClose);
  }, []);

  return {
    forceModalOpen: forceModalOpen,
    toggleModalOpen: toggleModalOpen,
    MessageMovieModal: (
      <MessageMovieModal
        isModalOpen={open}
        setIsModalOpen={setOpen}
        island={island}
        handleBoxClose={handleBoxClose}
      />
    ),
  };
};

type Props = {
  isModalOpen: boolean;
  island: islandType;
  handleBoxClose: () => void;
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

const MessageMovieModal: FC<Props> = ({ isModalOpen, setIsModalOpen, island, handleBoxClose }) => {
  const messagePrefix = continentToJenreDict[island] + '（解説）';
  const videoLink = introVideoIslandDict[island];

  return (
    <CustomizedModalWrapper isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen}>
      <div
        style={{
          fontWeight: 'bold',
          padding: '4px',
          fontSize: '12pt',
        }}
      >
        {messagePrefix}
      </div>
      <MessageMovieView link={videoLink} />
      <div
        style={{ textAlign: 'center', width: '40%', marginLeft: '30%' }}
        onClick={handleBoxClose}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: '12px',
            border: '#ffa500 solid 2px',
            borderRadius: '40px',
            background: 'linear-gradient(to bottom, #ffd700, #ffa500)',
          }}
        >
          <div style={{ color: '#ffffff', fontWeight: 'bold' }}>とじる&nbsp;</div>
          <div>
            <img src={treasure_box_open} alt="" width="40px"></img>
          </div>
        </div>
      </div>
    </CustomizedModalWrapper>
  );
};

type MessageMovieViewProps = {
  link: string;
};

export const MessageMovieView: FC<MessageMovieViewProps> = memo(({ link }) => {
  const size = useWindowSize();
  console.log(size);
  const { width, height } = calculateVideoScreen(size);

  return (
    <>
      <iframe
        width={width}
        height={height}
        src={link + '?rel=0&modestbranding=1'}
        title="YouTube video player"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        style={{ marginBottom: '8px' }}
      ></iframe>
    </>
  );
});

type sizeType = number[];
export const calculateVideoScreen = (size: sizeType) => {
  return {
    width: Math.trunc(size[0] * 0.85),
    height: Math.trunc(size[0] * 0.582),
  };
};
